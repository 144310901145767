import React, { useState, useSyncExternalStore } from "react";
import HomeService from "../../Service/HomeService";
import HttpClientXml from "../../Utils/HttpClientXml";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import ReactTags from "react-tag-autocomplete";
import SelectableInput from "../../Component/SelectableInput";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";

// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// import { DatePicker } from "@mui/x-date-pickers";

import DatePicker from "react-datepicker";

const Books = () => {
  const [hide, setHide] = useState(true);

  const [year, setYear] = useState("");

  console.log("YEar", year);
  const [month, setMonth] = useState("");

  const [dates, setDates] = useState("");

  const [startDate, setStartDate] = useState(new Date());

  const [hide1 , setHide1] = useState(false);
  const [hide2 , setHide2] = useState(false);

  const [image, setImage] = useState("");

  console.log("UploadCollectionImage", image);
  const [img, setImg] = useState("");
  const [imgs, setImgs] = useState("");
  const [collectionCategoryId, setCategoryCollectionId] = useState("");

  const [themeCollection, setThemeCollection] = useState("");
  const [catTypeId, setCatTypeId] = useState("");
  console.log("ddddddddddd", catTypeId);

  const [AllCatData, setAllCatData] = useState([]);
  const [catType, setCatType] = useState([]);
  console.log("catTypettttttt", catType);

  const [title, setTitle] = useState("");
  const [content , setContent] = useState("")
  const [summery, setSummery] = useState("");
  const [author, setAuthor] = useState("");
  const [desc, setDesc] = useState("");
  const [partner, setPartner] = useState("");
  const [contribution_institution, setContribution_institution] = useState("");
  const [subject, setSubject] = useState("");
  const [url, setUrl] = useState("");
  const [license, setLicense] = useState("");

  const [collectionUrl, setCollectionUrl] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [fiction, setFiction] = useState(Boolean);
  const [date, setDate] = useState("");
  const [dateType, setDateType] = useState("");

  const [publishDate, setpublishDate] = useState("");

  const [isFeatured, setIsFeatured] = useState(false);
  const [searchTag, setSearchTag] = useState([]);

  const [allthemeCollection, setAllThemeCollection] = useState([]);
  console.log("publishDate", publishDate);

  useEffect(() => {
    fetchAllCollCatg();
    fetchCategoryType();
    fetchThemeCollection();
  }, []);

  const renderMonthContent = (month, shortMonth, longMonth) => {
    const tooltipText = `Tooltip for month: ${longMonth}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const fetchAllCollCatg = () => {
    HomeService.AllCollectionCategory()
      .then((res) => {
        if (res && res?.status) {
          setAllCatData(res?.data);
        }
        console.log("RESCAT", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchCategoryType = () => {
    let arr = [];
    HomeService.getAllCollType()
      .then((res) => {
        if (res && res.status) {
          res?.data.forEach((element) => {
            arr.push(...element.categoryType);
          });
          setCatType(arr);
        }
        console.log("AAAAAAAAAAAAA", arr);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchThemeCollection = () => {
    HomeService.AllThematicCollection()
      .then((res) => {
        if (res && res?.status) {
          setAllThemeCollection(res?.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const AddCollection = () => {
    console.log("Fiction", fiction, date);

    let data = {
      catId: collectionCategoryId,
      type: "Books",
      themeId: themeCollection,
      title: title,
      // isFeatured:true,
      uploadCollection: image,
      // link:collectionUrl,
      image: img,
      collectionImage: imgs,
      summery: summery.replace("<p>", "").replace("</p>", ""),
      author: author,
      desc: desc,
      partner: partner,
      contribution_institution: contribution_institution,
      subject: subject,
      url: url,
      isFiction: fiction,
      publishDate: publishDate ,
      license: license,
      searchTag: searchTag,
      content: content,
      isFeatured,
    };
    console.log("Data", data);
    if (collectionCategoryId && title && summery) {
      if (!searchTag.length) {
        toast.error("Press ‘ ENTER ’ after writing each tag");
        return;
      }

      if(new Date(publishDate) > new Date()){
      toast.error('Publish date must not be Future Date……It must be either Past date or Current Date');
          return
      }
      HomeService.AddCollection(data)
        .then((res) => {
          console.log("Response Add Collection", res);
          if (res && res.status) {
            toast.success("Collection added successfully");
            setCategoryCollectionId("");
            setThemeCollection("");
            setTitle("");
            setImage("");
            setImg("");
            setImgs("");
            setSummery("");
            setContent("");
            setCollectionUrl("");
            setpublishDate("")
            setAuthor("");
            setDesc("");
            // setFiction(false);
            setDate("");
            setPartner("");
            setContribution_institution("");
            setSubject("");
            setHide1(false);
            setHide2(false)
            setUrl("");
            setLicense("");
            let file = document.querySelector("#images");
            file.value = "";
            let files = document.querySelector("#image");
            files.value = "";
            let filess = document.querySelector("#img");
            filess.value = "";
            
            setIsFeatured(false);
            setSearchTag([]);
          } else {
            toast.error("Collection Title Already Exists");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Mandatory fields are required");
    }
  };

  const handleCollCatName = (e) => {
    setCategoryCollectionId(e.target.value);
    setHide(false);
  };

  const handleCollCatType = (e) => {
    setCatTypeId(e.target.value);
  };

  const imageHandler = async (e) => {
    setHide2(true);
    console.log("PDF", e.target.files);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("doc", file);

    if (file?.type === "application/pdf") {
      let res = await HttpClientXml.fileUplode(
        "upload-collection-document",
        "POST",
        data
      );
      if (res && res.status) {
        toast.success("File Uploaded Successfully");
        setImage(`http://13.127.222.180:3056/${res?.image}`);
      } else {
        toast.error("Failed to upload ");
      }
    } else {
      toast.error("Only Upload Pdf file");
      let file = document.querySelector("#images");
      file.value = "";
    }
  };

  const imageHandlers = async (e) => {
    toast.success("Please upload images with a height of 200 pixels and a width of 150 pixels.")
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);

    let res = await HttpClientXml.fileUplode(
      "upload-Photocategory",
      "POST",
      data
    );
    if (res && res.status) {
      toast.success(" Uploaded Successfully");
      setImg(res?.doc?.url);
    } else {
      toast.error("Failed to upload Image");
    }
  };

  const imageHandlerss = async (e) => {
    toast.success("Please upload images with a height of 240 pixels and a width of 186 pixels.")
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);

    let res = await HttpClientXml.fileUplode(
      "upload-Photocategory",
      "POST",
      data
    );
    if (res && res.status) {
      toast.success(" Uploaded Successfully");
      setImgs(res?.doc?.url);
    } else {
      toast.error("Failed to upload Image");
    }
  };

  const handlethemeCollection = (e) => {
    setThemeCollection(e.target.value);
  };

  const HandleCrossClick = () => {
    setHide2(false)
    setImage("");
    let file = document.querySelector("#images");
    file.value = "";
  };

  const HandleCrossClicks = () => {
    setImg("");
    let file = document.querySelector("#image");
    file.value = "";
  };

  const HandleCrossClickss = () => {
    setImgs("");
    let file = document.querySelector("#img");
    file.value = "";
  };

  const HandleFiction = (e) => {
    if (e.target.checked) {
      setFiction(true);
    }
  };

  const renderYearContent = (year) => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };

  const HandleFiction1 = (e) => {
    if (e.target.checked) {
      setFiction(false);
    }
  };

  return (
    <>
      <div className="card-body">
        <div
          style={{
            textAlign: "center",
            fontSize: "20px",
            color: "#868e96",
            margin: "35px",
          }}
          className="card-title"
        >
          Add Books
        </div>

        <label for="exampleInputEmail1">
          Collection Category<span style={{ color: "red" }}>*</span> :
        </label>

        <select
          style={{ marginBottom: "21px" }}
          class="form-select"
          aria-label="select category"
          value={collectionCategoryId}
          onChange={(e) => handleCollCatName(e)}
        >
          <option value={""}>Select a collection category.......</option>
          {AllCatData.map((item) => {
            return (
              <option id={item?._id} value={item?._id}>
                {item?.name}
              </option>
            );
          })}
        </select>

        <label for="exampleInputEmail1">Theme :</label>

        <select
          style={{ marginBottom: "21px" }}
          class="form-select"
          aria-label="select theme"
          value={themeCollection}
          onChange={(e) => handlethemeCollection(e)}
          // disabled={hide}
        >
          <option value={""}>Select a theme.......</option>
          {allthemeCollection.map((item) => {
            return (
              <option id={item?._id} value={item?._id}>
                {item?.name}
              </option>
            );
          })}
        </select>

        <div class="form-group">
          <label for="exampleInputEmail1">
            Title<span style={{ color: "red" }}>*</span> :
          </label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter title"
          />
        </div>

        {/* <div class="form-group">
          <label for="exampleInputEmail1">
            Collection Url  :
          </label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={collectionUrl}
            onChange={(e) => setCollectionUrl(e.target.value)}
            placeholder="Enter Collection url.."
          />
        </div> */}

        <div class="mb-3">
          <label for="formFile" class="form-label">
            Upload Collection :
          </label>
          <input
            id="images"
            onChange={imageHandler}
            class="form-control"
            accept="application/pdf"
            type="file"
            disabled={hide1}
          />
          {image && (
            <>
              <svg
                style={{ height: "15%", width: "15%" }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-6 h-6"
              >
                <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625z" />
                <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
              </svg>
              <button
                onClick={() => HandleCrossClick()}
                style={{ color: "red" }}
                type="button"
                class="btn-close"
                aria-label="Close"
              ></button>
            </>
          )}
        </div>

                <div class="form-group">
          <label for="exampleInputEmail1">URL :</label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={url}
            onChange={(e) =>{ e.target.value === ""? setHide1(false): setHide1(true); setUrl(e.target.value)}}
            placeholder="Enter URL"
            disabled={hide2}
          />
        </div>

        <div class="mb-3">
          <label for="formFile" class="form-label">
            Thumbnail Image :
          </label>
          <input
            id="image"
            onChange={imageHandlers}
            class="form-control"
            accept="image/*"
            type="file"
          />
          {img && (
            <>
              <img
                style={{
                  height: "10%",
                  width: "10%",
                  marginTop: "12px",
                  borderRadius: "12px",
                }}
                src={img}
              />
              <button
                onClick={() => HandleCrossClicks()}
                style={{ color: "red" }}
                type="button"
                class="btn-close"
                aria-label="Close"
              ></button>
            </>
          )}
        </div>
        <div class="mb-3">
          <label for="formFile" class="form-label">
            Collection Image :
          </label>
          <input
            id="img"
            onChange={imageHandlerss}
            class="form-control"
            type="file"
            accept="image/*"
          />
          {imgs && (
            <>
              <img
                style={{
                  height: "10%",
                  width: "10%",
                  marginTop: "12px",
                  borderRadius: "12px",
                }}
                src={imgs}
              />
              <button
                onClick={() => HandleCrossClickss()}
                style={{ color: "red" }}
                type="button"
                class="btn-close"
                aria-label="Close"
              ></button>
            </>
          )}
        </div>

        {/* <div class="form-group">
          <label for="exampleInputEmail1">
            Summary<span style={{ color: "red" }}>*</span> :
          </label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={summery}
            onChange={(e) => setSummery(e.target.value)}
            placeholder="Enter summary"
          />
        </div> */}
        <label for="exampleInputEmail1">
          Summary<span style={{ color: "red" }}>*</span> :
        </label>

        <div style={{ marginBottom: "21px" }}>
          <CKEditor
            editor={ClassicEditor}
            data={summery}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              // let arr = [];
              // let data = editor.getData();

              setSummery(editor.getData());
            }}
            onBlur={(event, editor) => {
              console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              console.log("Focus.", editor);
            }}
          />
        </div>

        <div class="form-group">
          <label for="exampleInputEmail1">Author :</label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            placeholder="Enter author"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Description :</label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            placeholder="Enter description"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Partner :</label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={partner}
            onChange={(e) => setPartner(e.target.value)}
            placeholder="Enter partner"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Contributing Institution :</label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={contribution_institution}
            onChange={(e) => setContribution_institution(e.target.value)}
            placeholder="Enter Contributing Institution"
          />
        </div>

        <div class="form-group">
          <label for="exampleInputEmail1">Subject :</label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="Enter subject"
          />
        </div>


        <div class="form-group">
          <label for="exampleInputEmail1">License / Right :</label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={license}
            onChange={(e) => setLicense(e.target.value)}
            placeholder="Enter license"
          />
        </div>

        {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}/> */}

        {/* <DatePicker /> */}
        {/* value={value} onChange={(newValue) => setValue(newValue)}  */}

        {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
        {/* <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}> */}
        {/* <DatePicker
          label={'"year", "month" and "day"'}
          views={['year', 'month', 'day']}
        />
        <DatePicker label={'"day"'} views={['day']} />
        <DatePicker label={'"month" and "year"'} views={['month', 'year']} /> */}
        {/* </DemoContainer> */}

        {/* </LocalizationProvider> */}
        <div class="form-group">
          <label for="exampleInputEmail1">
            Publish Date Type :
          </label>

          <select
            style={{ marginBottom: "21px" }}
            class="form-select"
            aria-label="select category"
            value={dateType}
            onChange={(e) => {
              setpublishDate("");
              setDateType(e.target.value);
            }}
          >
            <option value={""}>Select a publish date type.......</option>
            {["year", "month", "date"].map((item) => {
              return <option value={item}>{item}</option>;
            })}
          </select>
        </div>

        {dateType === "year" && (
          <div style={{ marginBottom: "12px" }}>
            <label for="exampleInputEmail1"> Select Year :</label>
            <DatePicker
              className="form-control"
              selected={ new Date(publishDate,0) }
         
              renderYearContent={renderYearContent}
              showYearPicker
              onChange={(year) => {
                console.log("ssssssss", moment(year).year());
               
                setpublishDate(moment(year).year());
              }}
              dateFormat="yyyy"
            />
          </div>
        )}

        {dateType === "month" && (
          
           
          <div style={{ marginBottom: "12px" }}>
             <label for="exampleInputEmail1">Select Month & Year :</label>
            <input
              className="form-control"
              type="month"
              value={publishDate}
              onChange={(e) => {
                setpublishDate(e.target.value);
              }}
            />
          </div>
        )}

        {dateType === "date" && (
          <div style={{ marginBottom: "12px" }}>
                         <label for="exampleInputEmail1">Select Full Date :</label>
            
            <input
              className="form-control"
              type="date"
              value={publishDate}
              onChange={(e) => {
                setpublishDate(e.target.value);
              }}
            />
          </div>
        )}

        <label for="exampleInputEmail1">SubCategory :</label>

        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            value={fiction}
            id="flexRadioDefault1"
            onChange={(e) => HandleFiction(e)}
          />
          <label class="form-check-label" for="flexRadioDefault1">
            Fiction
          </label>
        </div>
        <div class="form-check" style={{ marginBottom: "34px" }}>
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            value={fiction}
            id="flexRadioDefault2"
            onChange={(e) => HandleFiction1(e)}
          />
          <label class="form-check-label" for="flexRadioDefault2">
            Non Fiction
          </label>
        </div>

        <div className="form-group">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue=""
              id="featured"
              checked={isFeatured}
              onChange={(val) => setIsFeatured(val.target.checked)}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Featured
            </label>
          </div>
        </div>
        <SelectableInput
          title="Add Book Search Tag"
          value={searchTag}
          onChange={(val) => setSearchTag(val)}
          className="form-group"
          placeholder="Enter keyword(s) to search Books"
        />

        <div class="form-group">
          <label for="exampleInputEmail1">
            Content<span style={{ color: "red" }}>*</span> :
          </label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Enter content"
          />
        </div>

        <button
          style={{ textAlign: "left", float: "right" }}
          onClick={() => {
            AddCollection();
          }}
          type="button"
          class="btn btn-warning"
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default Books;
