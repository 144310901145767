import React, { useEffect } from 'react'
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import HomeService from '../../Service/HomeService';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';

const FlokDesc = () => {
    const [description , setDescription] = useState('');

    const [descriptionData , setDescriptionData] = useState([]);

    const [hide , setHide] = useState(true);
    const [id , setId] = useState('')

    useEffect(() => {
        fetchDescription();
    },[])

    const onEdit = (item) => {
        console.log("item",item);
        setDescription(item?.description);
        setId(item?._id)
        setHide(false);    
    }




    

    const fetchDescription = () => {
        HomeService.FlokloreDescription()
        .then((res) => {
            // console.log("ResAllBlog" , res.data);
          if (res && res?.status) {
            // let data = []
            // setLoader(false)
            let arr = [res?.data]?.map((item, index) => {
                console.log("Item" , item);
              return {
                sl: index + 1,
                description: item?.description,

  
                action: (
                  <div style={{ display: "flex", flexDirection: "coloum" }}>
                    <svg
                      onClick={() => onEdit(item)}
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                        marginRight: "20px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>
                    {/* <svg
                      onClick={() => onDelete(item?._id)}
                      style={{
                        color: "red",
                        height: "20px",
                        cursor: "pointer",
                        width: "20px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-trash3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                    </svg> */}
                  </div>
                ),
              };
            });
            setDescriptionData(arr);
          }
          console.log("RESPONSE", res);
        })
        .catch((err) => {
        //   setLoader(false)
          console.log("err", err);
        });
    }

    const AddDesc = () =>{
        let data = {
            description:description,
        }
    
        if(description){
          HomeService.AddFlokloreDescription(data)
          .then((res) => {
              console.log("Response Add Blog Category" , res);
              if(res && res.status){
                  toast.success('Description  added successfully');
                  fetchDescription();
                  setDescription("");
                 
              }else{
                toast.error(" Backend Error");
              }
          })
          .catch((err) => {
            
              console.log(err);
          })
        }else{
          toast.error("Description Field Is Required");
        }
    }

    const columns = [
        {
          name: <div style={{ fontSize: "14px",color:'#495057', fontWeight: "bolder" }}>SL</div>,
          selector: (row) => row.sl,
          sortable: true,
          width:"80px",
        },
        {
          name: (
            <div style={{ fontSize: "14px", color:'#495057',  fontWeight: "bolder" }}>
              Description
            </div>
          ),
          selector: (row) => row.description,
        },

        // {
        //   name: (
        //     <div
        //       style={{ fontSize: "14px", color:'#495057', marginLeft: "15px", fontWeight: "bolder" }}
        //     >
        //       Action
        //     </div>
        //   ),
        //   selector: (row) => row.action,
        // },
      ];

      const UpdateDesc = () => {
        console.log("ID", id);
        let data = {
            description:description,
        }
        HomeService.UpdateBlogCategory(id ,data)
        .then((res) => {
            console.log("updated res", res);
          if(res && res.status) {
            toast.success("Updated Successfully");
            setHide(true)
            console.log("RESPONSEDELETE",res);
            setDescription("")
            fetchDescription();
          }else{
            toast.error("Blog Category Name Already Exist");
          }
        })
        .catch((err) => {
          console.log(err);
        })
      }

  return (
    <>
    <div component="div" className="TabsAnimation appear-done enter-done">
<div className="main-card mb-3 card">
 <div className="card-body">
    {hide ? <div style={{ textAlign: "center" , fontSize: "20px" , color:'#868e96',  margin:'35px'}} className="card-title">
     Add Description
   </div> : <div style={{ textAlign: "center" , fontSize: "20px" , color:'#868e96',  margin:'35px'}} className="card-title">
     Edit Description
   </div> }
   
   <div class="form-group">
     <label for="exampleInputEmail1">Description<span style={{ color:'red'}}>*</span> :</label>
     <input
       type="text"
       class="form-control"
       id="exampleInputEmail1"
       aria-describedby="emailHelp"
       value={description}
       onChange={(e) => setDescription(e.target.value)}
       placeholder="Enter description..."
     />
   </div>


    {
        hide ?    <button  class="btn btn-primary" onClick={AddDesc}>
        Submit
      </button> :    <button  class="btn btn-primary" onClick={UpdateDesc}>
       Update
   </button>
    }



   <div style={{ textAlign: "center" , fontSize: "20px" , color:'#868e96',  margin:'35px'}} className="card-title">
     Manage Description
   </div>
   <DataTable columns={columns} data={descriptionData}   pagination/>
 </div>
</div>
</div>
</>
  )
}
export default FlokDesc