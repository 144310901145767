import React, { useEffect } from 'react'
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import HomeService from '../../Service/HomeService';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import ImageLoader from "../../Loader/ImageLoader";
import HttpClientXml from '../../Utils/HttpClientXml';

const AddandManageContributerLogo = () => {


  const [name , setName] = useState('');
  const [ imgLoader , setImgLoader] = useState(false);
  const [image , setImage] = useState('');

  const [categoryName , setCategoryName] = useState([]);

  const [hide , setHide] = useState(true);
  const [id , setId] = useState('')


  const HandleCrossClick = () => {
    setImage("");
    let file = document.querySelector("#images");
    file.value = "";
  }

  useEffect(() => {
      fetchBlogCategory();
  },[])



  const onDelete = (id) => {

      Swal.fire({
          title: 'Are you sure?',
          // text: "You won't  to delete this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            console.log("hhhhhhhhhhhhhhhhhhhhhhhhh");
              HomeService.DeleteContributingPartnersLogo(id)
              .then((res) => {
                console.log("DELETEBLOGCAT", res);
                if(res && res.status) {
                  toast.success("Deleted Successfully");
                  console.log("RESPONSEDELETE",res);
                  fetchBlogCategory();
                }else{
                  toast.error("Failed to Delete the Item ");
                }
              })
              .catch((err) => {
                console.log(err);
              })
          }
        })
  }

  

  const fetchBlogCategory = () => {
      HomeService.ContributingPartnersLogo()
      .then((res) => {
          console.log("AllImage" , res.data);
        if (res && res?.status) {
          // setLoader(false)
          let arr = res?.data?.map((item, index) => {
            return {
              sl: index + 1,
       
              Image:
              <>
              {
                (item?.logo) ? <img style={{ height: '39%', width: '39%' , borderRadius:'9px' , margin:"5px" }} src={item?.logo} /> :
                <img style={{ height: '11%', width: '11%' , borderRadius:'9px' , margin:"5px" }} src={"https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"} />
              }
              </>,

              action: (
                <div style={{ display: "flex", flexDirection: "coloum" }}>
           
                  <svg
                    onClick={() => onDelete(item?._id)}
                    style={{
                      color: "red",
                      height: "20px",
                      cursor: "pointer",
                      width: "20px",
                      marginLeft:"19px"
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </div>
              ),
            };
          });
          setCategoryName(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
      //   setLoader(false)
        console.log("err", err);
      });
  }

  const AddBlogCategory = () =>{
      let data = {
        logo: image,
      }
  
      if(image){
        HomeService.AddContributingPartnersLogo(data)
        .then((res) => {
            console.log("Response Add Blog Category" , res);
            if(res && res.status){
                toast.success("Logo is added successfully");
                fetchBlogCategory();
                setImage("");
                let file = document.querySelector("#images");
                file.value = "";
               
            }else{
              toast.error(res?.message);
            }
        })
        .catch((err) => {
          
            console.log(err);
        })
      }else{
        toast.error("Image field is required");
      }
  }

  const columns = [
      {
        name: <div style={{ fontSize: "14px",color:'#495057', fontWeight: "bolder" }}>SL</div>,
        selector: (row) => row.sl,
        sortable: true,
        width:"160px",
      },
      {
        name: (
          <div style={{ fontSize: "14px", color:'#495057',  fontWeight: "bolder" }}>
            Image
          </div>
        ),
        selector: (row) => row.Image,
      },

      {
        name: (
          <div
            style={{ fontSize: "14px", color:'#495057', marginLeft: "15px", fontWeight: "bolder" }}
          >
            Action
          </div>
        ),
        selector: (row) => row.action,
      },
    ];

    const imageHandler = async (e) => {
      setImgLoader(true);
      let file = e.target.files[0];
      let data = new FormData();
      data.append("image", file);
  
      let res = await HttpClientXml.fileUplode("upload-logo-image" , "POST" , data);
      if(res && res.status){
        toast.success("Image uploaded successfully");
        setImage(res?.doc?.url);
        setImgLoader(false);
      }else{
        toast.error("Failed to upload Image");
        setImgLoader(false);
      }
    };
  



  return (
    <>
    <div component="div" className="TabsAnimation appear-done enter-done">
<div className="main-card mb-3 card">
 <div className="card-body">
    <div style={{ textAlign: "center" , fontSize: "20px" , color:'#868e96',  margin:'35px'}} className="card-title">
   Add Our Contributing Partners Logo
   </div> 
   
   <div class="mb-3">
              <label for="formFile" class="form-label">
                Upload Image<span style={{ color:'red'}}>*</span> :
              </label>
              <input
                id="images"
                onChange={imageHandler}
                class="form-control"
                type="file"
                accept="image/*"
              />
              {imgLoader ? <ImageLoader/> : null}
              {image &&
              <><img style={{ height: "10%", width: "10%" , marginTop:'12px' , borderRadius:'9px' }} src={image} />
                <button onClick={() => HandleCrossClick()} style={{ color: 'red'}} type="button" class="btn-close" aria-label="Close"></button>
              </>
               }
            </div>


<button  class="btn btn-primary" onClick={AddBlogCategory}>
        Submit
      </button> 



   <div style={{ textAlign: "center" , fontSize: "20px" , color:'#868e96',  margin:'35px'}} className="card-title">
   Manage Our Contributing Partners Logo
   </div>
   <DataTable columns={columns} data={categoryName}   pagination/>
 </div>
</div>
</div>
</>
  )
}

export default AddandManageContributerLogo