import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { useEffect } from "react";
import HomeService from "../../Service/HomeService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
import Loader from "../../Loader/Loader";
import moment from "moment";
import HttpClientXml from "../../Utils/HttpClientXml";

const Users = () => {
  const [comment, setallComments] = useState([]);
  const [loader, setLoader] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    fetchAllComments();
  }, []);

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HomeService.DeleteComment(id)
          .then((res) => {
            console.log("RESPONSEDELETE", res);
            if (res && res.status) {
              toast.success(" Deleted successfully");
              fetchAllComments();
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const fetchAllComments = () => {
    HomeService.ViewAllComments()
      .then((res) => {
        console.log("ResAllComments", res.data);
        if (res && res?.status) {
          setLoader(false);
          let arr = res?.data?.map((item, index) => {
            return {
              sl: index + 1,
              Name: item?.user?.name,
              blog: item?.blog?.blogTitle,
              comments: item?.comments,
              status: item?.hasShown ? (
                <button
                  onClick={() => toast.success("Already Approved")}
                  type="button"
                  class="btn btn-success"
                >
                  Approved
                </button>
              ) : (
                <button
                  onClick={() => handleApproved(item?._id)}
                  type="button"
                  class="btn btn-secondary"
                >
                  Approve
                </button>
              ),
              action: (
                <svg
                  onClick={() => onDelete(item?._id)}
                  style={{
                    color: "red",
                    height: "20px",
                    cursor: "pointer",
                    width: "20px",
                    marginLeft: "12px",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-trash3"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                </svg>
              ),
            };
          });
          setallComments(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  const handleApproved = (id) => {
    HomeService.IsApproved(id)
      .then((res) => {
        if (res && res?.status) {
          toast.success("Comment approved successfully");
          fetchAllComments();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Users
        </div>
      ),
      selector: (row) => row.Name,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Blogs
        </div>
      ),
      selector: (row) => row.blog,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Comments
        </div>
      ),
      selector: (row) => row.comments,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Status
        </div>
      ),
      selector: (row) => row.status,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
    },
  ];
  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {loader ? (
              <div style={{ textAlign: "center" }}>
                {" "}
                <Loader />{" "}
              </div>
            ) : null}
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              All Comments List
            </div>
            <DataTable columns={columns} data={comment} pagination />
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
