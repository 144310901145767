import React, { useEffect } from 'react';
import './App.css';
import PrivateRoutes from './View/PrivateRoutes';
import { BrowserRouter as Router, Routes, Route, redirect, useNavigate } from 'react-router-dom';
import Dashboard from "../src/View/Dashboard/Index"
import '../src/assets/base.css'
import Main from './Layout/Main';
import AddSubAdmin from './View/SubAdmin/AddSubAdmin';
import AddMarchent from './View/Marchent/AddMarchent';
import Login from './Athentication/Login';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from './Redux/Reducer/User';
import { Toaster } from 'react-hot-toast';

import ManageType from './View/Startups/ManageType';
// import ManageType from './View/Education/ManageEducationType';
import ManageEducationType from './View/Education/ManageEducationType';

import AddCollectionCategory from './View/CollectionCategory/AddCollectionCategory';
import ManageCollectionCategory from './View/CollectionCategory/ManageCollectionCategory';
import EditCollectionCategory from './View/CollectionCategory/EditCollectionCategory';

import AddCollectionType from './View/CollectionType/AddCollectionType';
import ManageCollectionType from './View/CollectionType/ManageCollectionType';
import EditCollectionType from './View/CollectionType/EditCollectionType';

import AddCollection from './View/Collection/AddCollection';
import ManageCollection from './View/Collection/ManageCollection';
import EditCollection from './View/Collection/EditCollection';

import AddPhotography from './View/Photography/AddPhotography';
import ManagePhotography from './View/Photography/ManagePhotography';
import EditPhotography from './View/Photography/EditPhotography';

import AddPhotoGallery from './View/Photography/AddPhotoGallery';
import ManagePhotoGallery from './View/Photography/ManagePhotoGallery';
import EditPhotoGallery from './View/Photography/EditPhotoGallery';

import AddThematicCollection from './View/ThematicCollection/AddThematicCollection';
import ManageThematicCollection from './View/ThematicCollection/ManageThematicCollection';
import EditThematicCollection from './View/ThematicCollection/EditThematicCollection';

import AddandManageContributerLogo from './View/Contributer logo/AddandManageContributerLogo';


import EditDetails from './View/Startups/EditDetails';
import AddType from './View/Startups/AddType';
import EditTypes from './View/Education/EditTypes';

import AddBlogCategory from './View/BlogCategory/AddBlogCategory';
import ManageBlogCategory from './View/BlogCategory/ManageBlogCategory';
import EditBlogCategory from './View/BlogCategory/EditBlogCategory';

import AddBlog from './View/Blog/AddBlog';
import ManageBlog from './View/Blog/ManageBlog';
import EditBlog from './View/Blog/EditBlog';

import Users from './View/Comments/Users';

import EditType from './View/Startups/EditType';

import AddTypes from './View/Education/AddTypes';

import EditDetailsEducation from './View/Education/EditDetailsEducation';

import ManageBanner from './View/Banners/ManageBanner';
import UserRegisterList from './View/Register/UserRegisterList';
import ChangePasswordForm from './View/ChangePassword/ChangePasswordForm';
import AddFolkLore from './View/FolkLore/AddFolkLore';
import EditFolkLore from './View/FolkLore/EditFolkLore';
import CollectionDesc from './View/Description/CollectionDesc';
import ThematicDesc from './View/Description/ThematicDesc';
import FlokDesc from './View/Description/FlokDesc';
import BlogDesc from './View/Description/BlogDesc'
import ManageFolkLore from './View/FolkLore/ManageFolkLore'
import { Manage } from './View/FolkLore/Manage';
import AddFolkVolume from './View/FolkLore/FolkVolume/AddFolkVolume';

function App() {


  return (
    <React.Fragment>
      <Toaster />
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route element={<Main />}>
            <Route path="/" element={<Dashboard />} />


            <Route path="/add-collection-category" element={<AddCollectionCategory />} />
            <Route path='/manage-collection-category' element={<ManageCollectionCategory />} />
            <Route path='/edit-collection-category' element={<EditCollectionCategory />} />

            <Route path="/add-collection-type" element={<AddCollectionType />} />
            <Route path='/manage-collection-type' element={<ManageCollectionType />} />
            <Route path='/edit-collection-type' element={<EditCollectionType />} />

            <Route path="/add-collection" element={<AddCollection />} />
            <Route path='/manage-collection' element={<ManageCollection />} />
            <Route path='/edit-collection' element={<EditCollection />} />

            <Route path="/add-photography" element={<AddPhotography />} />
            <Route path='/manage-photography' element={<ManagePhotography />} />
            <Route path='/edit-photography' element={<EditPhotography />} />

            <Route path="/add-photo-gallery" element={<AddPhotoGallery />} />
            {/* <Route path="/edit-photo-gallery" element={<AddPhotoGallery/>}/> */}

            <Route path='/manage-photo-gallery' element={<ManagePhotoGallery />} />
            <Route path='/edit-photo-gallery' element={<EditPhotoGallery />} />

            <Route path="/add-thematic-collection" element={<AddThematicCollection />} />
            <Route path='/manage-thematic-collection' element={<ManageThematicCollection />} />
            <Route path='/edit-thematic-collection' element={<EditThematicCollection />} />


            <Route path="/edit-details" element={<EditDetails />} />
            <Route path='/add-type' element={<AddType />} />

            <Route path='/all-comments' element={<Users />} />

            <Route path="/add-blog-category" element={<AddBlogCategory />} />
            {/* <Route path='/manage-blog-category' element={<ManageBlogCategory/>}/>
              <Route path='/edit-blog-category' element={<EditBlogCategory/>}/> */}

            <Route path="/add-blog" element={<AddBlog />} />
            <Route path='/manage-blog' element={<ManageBlog />} />
            <Route path='/edit-blog' element={<EditBlog />} />

            <Route path='/all-comments' element={<Users />} />

            <Route path='/add-types' element={<AddTypes />} />
            <Route path='/edit-type' element={<EditType />} />
            <Route path='/edit-types' element={<EditTypes />} />

            <Route path='/edit-details-education' element={<EditDetailsEducation />} />

            <Route path='/manage-banners' element={<ManageBanner />} />
            <Route path='/all-register-user-list' element={<UserRegisterList />} />

            <Route path='/manage-startup-type' element={<ManageType />} />
            <Route path='/manage-education-type' element={<ManageEducationType />} />


            <Route path='/change-password' element={<ChangePasswordForm />} />
            <Route path='/add-manage-contributer-logo' element={<AddandManageContributerLogo />} />

            <Route path="/add-folklore" element={<AddFolkLore />} />
            <Route path='/manage-folklore' element={<ManageFolkLore />} />
            <Route path='/edit-folklore' element={<EditFolkLore />} />

            <Route path='/add-folkVolume' element={<AddFolkVolume />} />

            <Route path="/collection-desc" element={<CollectionDesc />} />
            <Route path='/thematic-desc' element={<ThematicDesc />} />
            <Route path='/blog-desc' element={<BlogDesc />} />
            <Route path='/folklore-desc' element={<FlokDesc />} />
            <Route path='/manage-folklores' element={<Manage />} />

          </Route>
        </Route>

        <Route path="/login" element={<Login />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
