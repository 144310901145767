import React, { useEffect, useState } from "react";
import HomeService from "../../Service/HomeService";
import toast from "react-hot-toast";
import HttpClientXml from "../../Utils/HttpClientXml";
import { useLocation } from "react-router-dom";

const INITIAL = {
  name: "",
  image: "",
};


const AddPhotoGallery = () => {
  const location = useLocation();
  console.log("location", location);
  const [allPhotoCat, setAllPhotoCat] = useState([]);
  const [photoCategoryId, setPhotoCategoryId] = useState("");

  // const [initial , setInitial] = useState()

  const [AllCatData, setAllCatData] = useState([]);

  const [hide, setHide] = useState(true);

  const [collectionCategoryId, setCategoryCollectionId] = useState("");
  const [editEnable, setEditEnable] = useState(false);

  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [photoGallery, setPhotoGallery] = useState([INITIAL]);


    const [themeData , setThemeData] = useState([]);
  const [themeId , setThemeId] = useState('')


  useEffect(() => {
    fetchAllCollCatg();
    fetchThemeData();

    // if (
    //   location.pathname === "/edit-photo-gallery" &&
    //   location.state !== null
    // ) {
    //   let editData = location.state;
    //   console.log("state", location.state);
    //   setEditEnable(true);
    //   setCategoryCollectionId(editEnable.collectionCategoryId);
    //   setPhotoCategoryId(editData?.photoCategoryId);
    //   setPhotoGallery(editData?.photos);
    // }
  }, []);

    const fetchThemeData = () => {
    HomeService.AllThematicCollection()
        .then((res) => {
            console.log("ResThematicCollsadsd" , res.data);
          if (res && res?.status) {
            setThemeData(res?.data);
          }
      })
      .catch((err) => {
        
        console.log("err", err);
      });
  
    }


  const fetchAllCollCatg = () => {
    HomeService.AllCollectionCategory()
      .then((res) => {
        if (res && res?.status) {
          setAllCatData(res?.data);
        }
        console.log("RESCAT", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

    const handletheme = (e) => {
        setThemeId(e.target.data)
        // fetchAllPhotography(e.target.value);
    }

        const fetchAllPhotography = (id) => {
        console.log("LLLLLLLLLLLLL");
        //  let data ={
        //      themeId: id
        //  }

    
    HomeService.getAllPhotography(id)
      .then((res) => {
        console.log("responseget ALl PhotoGraphy" , res);
        if (res && res?.status) {
        setAllPhotoCat(res?.data)
          // setCategoryPhotography(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        // setLoader(false)
        console.log("err", err);
      });
  };

  const handleCollCatName = (e) => {
    setCategoryCollectionId(e.target.value);

    e.target.value === ""
      ? setCategoryCollectionId("")
      : fetchAllPhotography(e.target.value);
    setHide(false);
  };

  // const fetchAllPhotography = (id) => {
  //   console.log("ID", id);
  //   HomeService.getAllPhotography(id)
  //     .then((res) => {
  //       setAllPhotoCat(res?.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // const handleCollCatName = (e) => {

  //   setPhotoCategoryId(e.target.value);
  // };

  const changeHandler = async (e, i) => {
    if (e.target.name === "image") {
      // setIsLoading(true);

      // setPhotoGallery((prev) => {
      //   let update = JSON.parse(JSON.stringify(prev));
      //   update[i].isLoading == true;
      //   return [...update];
      // })

      let file = e.target.files[0];

      setPhotoGallery((prev) => {
        let update = JSON.parse(JSON.stringify(prev));
        update[i].image = URL.createObjectURL(file);
        return [...update];
      });

      let data = new FormData();
      data.append("image", file);
      try {
        let res = await HttpClientXml.fileUplode(
          "upload-Photocategory",
          "POST",
          data
        );
        if (res && res?.status) {
          toast.success("Image uploaded successfully");

          setPhotoGallery((prev) => {
            let update = JSON.parse(JSON.stringify(prev));
            update[i].image = res?.doc?.url;
            return [...update];
          });
        } else {
          toast.error("Failed to upload Image");
        }
      } catch (error) {
        toast.error("Failed to upload Image");
      }

      // setIsLoading(false);
      // setPhotoGallery((prev) => {
      //   let update = JSON.parse(JSON.stringify(prev));
      //   update[i].isLoading == false;
      //   return update;
      // })

      return;
    }
    setPhotoGallery((prev) => {
      let update = JSON.parse(JSON.stringify(prev));

      console.log("UPDATE", update);
      update[i][e.target.name] = e.target.value;
      return [...update];
    });
  };

  const HandlePhotogallery = () => {
    // if(isLoading) return;
    console.log("PHOTOTGALLERY", photoGallery);

    let data = {
      photoCatId: photoCategoryId,
      // themeId:themeId,
      photos: photoGallery,
    };

    if (
      collectionCategoryId &&
     
      photoCategoryId &&
      photoGallery[0].name &&
      photoGallery[0].image
    ) {
      HomeService.AddPhotoGallery(data)
        .then((res) => {
          console.log("Response Add Photography", res);
          if (res && res.status) {
            toast.success("Photo gallery added successfully");
            setPhotoCategoryId("");
            setCategoryCollectionId("");
            setPhotoGallery([INITIAL]);
            let file = document.querySelector("#image");
              file.value = "";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("All fields are required");
    }

    console.log("DataPHOto", data);
  };

  const HandleCrossClick = (i) => {
    // photoGallery[i].image == "";
    // setImage("");
    let file = document.querySelector("#image");
    file.value = "";
  };

  const HandlePhotogallerys = (e) => {
    setPhotoCategoryId(e.target.value);
  };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Add Photo gallery
            </div>
            <label for="exampleInputEmail1">
              Category<span style={{ color: "red" }}>*</span> :
            </label>
            <select
              style={{ marginBottom: "21px" }}
              class="form-select"
              aria-label="select category"
              value={collectionCategoryId}
              onChange={(e) => handleCollCatName(e)}
            >
              <option value={""}>Select a category.......</option>
              {AllCatData.map((item) => {
                return (
                  <option id={item?._id} value={item?._id}>
                    {item?.name}
                  </option>
                );
              })}
            </select>

      {/* <label for="exampleInputEmail1"> Theme :</label>
          <select
          style={{ marginBottom: "21px" }}
          class="form-select"
          aria-label="select category"
          value={themeId}
          onChange={(e) => handletheme(e)}
          // disabled={hide}
        >
          <option value={""}>Select a theme.......</option>
          {themeData.map((item) => {
            return (
              <option id={item?._id} value={item?._id}>
                {item?.name}
              </option>
            );
          })}
        </select> */}

            {/* <select
              style={{ marginBottom: "21px" }}
              class="form-select"
              aria-label="select category"
              value={collectionCategoryId}
              onChange={(e) => handleCollCatName(e)}
            >
              <option value={""}>Select a theme.......</option>
              {AllCatData.map((item) => {
                return (
                  <option id={item?._id} value={item?._id}>
                    {item?.name}
                  </option>
                );
              })}
            </select> */}


            <label for="exampleInputEmail1">
              {" "}
              Photocategory<span style={{ color: "red" }}>*</span> :
            </label>
            <select
              style={{ marginBottom: "21px" }}
              class="form-select"
              aria-label="select category"
              value={photoCategoryId}
              onChange={(e) => HandlePhotogallerys(e)}
              disabled={hide}
            >
              <option value={""}>Select a Photocategory.......</option>
              {allPhotoCat.map((item) => {
                return (
                  <option id={item?._id} value={item?._id}>
                    {item?.title}
                  </option>
                );
              })}
            </select>
            <div style={{ float: "right", marginRight: "0px" }}>
              <button
                class="btn btn-warning logout-btn"
                onClick={() => {
                  setPhotoGallery((prev) => {
                    let update = JSON.parse(JSON.stringify(prev));
                    update.push({
                      name: name,
                      image: image,
                    });
                    return [...update];
                  });
                }}
              >
                +
              </button>
            </div>

            {photoGallery.map((item, i) => {
              return (
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        marginBottom: "21px",
                        border: "0.01px solid #dee2e6",
                        width: "70%",
                        padding: "13PX",
                      }}
                    >
                      <div class="form-group">
                        <label
                          for="exampleInputEmail1"
                          style={{ marginBottom: "12px", fontSize: "15px" }}
                        >
                          Name<span style={{ color: "red" }}>*</span> :
                        </label>
                        <input
                          type="text"
                          name="name"
                          value={item?.name}
                          onChange={(e) => changeHandler(e, i)}
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter Name"
                        />
                      </div>
                      <div class="mb-3">
                        <label for="formFile" class="form-label">
                          Upload Image<span style={{ color: "red" }}>*</span> :
                        </label>
                        <input
                          id="image"
                          onChange={(e) => changeHandler(e, i)}
                          class="form-control"
                          name="image"
                          type="file"
                          accept="image/*"
                        />

                        {photoGallery[i]?.image !== "" && (
                          <>
                            {photoGallery[i]?.isLoading && (
                              <p style={{ color: "red" }}>
                                Uploading, Please wait ....
                              </p>
                            )}
                            <img
                              style={{
                                height: "25%",
                                width: "25%",
                                marginTop: "12px",
                                borderRadius: "12px",
                              }}
                              src={photoGallery[i]?.image}
                            />
                            {/* <button onClick={() => HandleCrossClick(i)} style={{ color: 'red'}} type="button" class="btn-close" aria-label="Close"></button> */}
                          </>
                        )}
                      </div>
                    </div>
                    <div> 
                    {i == 0 ? null : (
                      <>
                        <button
                          class="btn btn-danger"
                          style={{
                            margin: "104px 88px",
                            padding: "5px , 17px",
                          }}
                          onClick={() => {
                            setPhotoGallery((prv) => {
                              let update = JSON.parse(JSON.stringify(prv));
                              update.splice(i, 1);
                              return update;
                            });
                          }}
                        >
                          -
                        </button>
                      </>
                    )}
                    </div>
                  </div>
                </>
              );
            })}

            <button class="btn btn-primary" onClick={HandlePhotogallery}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPhotoGallery;
