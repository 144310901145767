import React, { useEffect, useState } from "react";
import HomeService from '../../Service/HomeService';
import HttpClientXml from "../../Utils/HttpClientXml";
import { toast } from "react-hot-toast";
import ImageLoader from "../../Loader/ImageLoader";
import { useLocation , useNavigate } from "react-router-dom";

const EditType = () => {

    
    const [name , setName] = useState('');
    const [description , setDescription] = useState('');
    const [link , setLink] = useState("");
    console.log(description)
    const [image , setImage] = useState('');
        const [ title , setTitle] = useState('')
   const [type , setType] = useState('');
    const [imageLoader , setImageLoader] = useState(false);

    const [id, setId] = useState('')

    const location = useLocation();
    const navigate = useNavigate();
    console.log("Image",image);




    useEffect(() => {
        if(location?.pathname === '/edit-type'){
            console.log("Location", location?.state);
            setType(location?.state?.type);
            setName(location?.state?.name);
            setImage(location?.state?.image);
            setDescription(location?.state?.description);
            setId(location?.state?._id);
            setLink(location?.state?.link)
        }
    }, [])

  const imageHandler = async (e) => {
    setImageLoader(true)
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);



    let res = await HttpClientXml.fileUplode("upload-Photocategory" , "POST" , data);
    if(res && res.status){
      toast.success("Image uploaded successfully");

      setImage(res?.doc?.url);
      setImageLoader(false)
    }else{
        setImageLoader(false)
      toast.error("Failed to upload Image")
    }
  };

const EditType = () => {
    let data = {
        themeType:'startup',
        type: type,
        name:name,
        description:description,
        link:link,
        image:image
    }
    if(name && type && description && image && link){
      HomeService.EditThematic(id ,data)
      .then((res) => {
          console.log("Response EditThematic" , res);
          if(res && res.status){
              toast.success("Updated Successfully");
              setName("");
              setDescription("");
              let file = document.querySelector("#images");
              file.value = "";
              setImage("");
              setLink("")
              setType("");
                navigate('/add-type')
          }else{
            toast.error(res?.message);
          }
      })
      .catch((err) => {
          console.log(err);
      })
    }else{
      toast.error("All Fields Are Required")
    }


}

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div
              style={{ textAlign: 'center', fontSize: '20px', color: '#868e96', margin: '35px' }}
              className="card-title"
            >
              Edit Thematic Type
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Type<span style={{ color: 'red' }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={type}
                onChange={e => setType(e.target.value)}
                placeholder="Enter type..."
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">
                Name<span style={{ color: 'red' }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Enter name..."
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">
                Link<span style={{ color: 'red' }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={link}
                onChange={e => setLink(e.target.value)}
                placeholder="Enter link..."
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">
                Description<span style={{ color: 'red' }}>*</span> :
              </label>
              <input
                type="text"
                value={description}
                onChange={e => setDescription(e.target.value)}
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Enter description..."
              />
            </div>

            <div class="mb-3">
              <label for="formFile" class="form-label">
                Upload Image<span style={{ color: 'red' }}>*</span> :
              </label>
              <input id="images" onChange={imageHandler} class="form-control" accept="image/*" type="file" />
              {imageLoader ? (
                <>
                  <ImageLoader />{' '}
                </>
              ) : null}
              {image && (
                <img style={{ height: '30%', width: '30%', marginTop: '12px', borderRadius: '9px' }} src={image} />
              )}
            </div>

            <button class="btn btn-primary" onClick={EditType}>
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditType
