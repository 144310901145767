import React, { useEffect, useState } from "react";
import HomeService from "../../Service/HomeService";
import HttpClientXml from "../../Utils/HttpClientXml";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ImageLoader from "../../Loader/ImageLoader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EditThematicCollection = () => {
  const [name, setName] = useState("");
  const [summary, setSummary] = useState("");
  const [content , setContent] = useState("")
  const [image, setImage] = useState("");
  const [id, setId] = useState("");
  const [imgLoader, setImgLoader] = useState(false);
  const [caption, setCaption] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const imageHandler = async (e) => {
    setImgLoader(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);

    // let res = await HttpClientXml.fileUplode(
    //   "upload-collection-document",
    //   'POST',
    //   file
    // );

    let res = await HttpClientXml.fileUplode(
      "upload-Photocategory",
      "POST",
      data
    );
    if (res && res.status) {
      toast.success("Image Uploaded Successfully");
      setImage(res?.doc?.url);
      setImgLoader(false);
    } else {
      toast.error("Failed to Uploaded Image");
      setImgLoader(false);
    }
  };

  useEffect(() => {
    if (location?.pathname == "/edit-thematic-collection") {
      setName(location?.state?.name);
      setContent(location?.state?.content)
      setSummary(location?.state?.summary);
      setId(location?.state?._id);
      setImage(location?.state?.img);
      location?.state?.caption && setCaption(location?.state?.caption);
    }
  }, []);

  const HandleCrossClick = () => {
    setImage("");
    let file = document.querySelector("#images");
    file.value = "";
  };

  const EditThematicCollection = () => {
    let data = {
      name: name,
      img: image,
      summary: summary.replace("<p>", "").replace("</p>", ""),
      caption: caption,
      content:content
    };
    if (name && summary && image && caption && content) {
      HomeService.EditThematicCollection(id, data)
        .then((res) => {
          console.log("Response Edit", res);
          if (res && res.status) {
            toast.success(res.message);
            setName("");
            setSummary("");
            let file = document.querySelector("#images");
            file.value = "";
            setImage("");
            setContent("");
            setCaption("");
            navigate("/manage-thematic-collection");
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("All Fields Are Required");
    }
  };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Edit Details In Other
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">
                Name<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter name"
              />
            </div>
            {/* <div class="form-group">
              <label for="exampleInputPassword1">Summary<span style={{ color:'red'}}>*</span> :</label>
              <input
                type="text"
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Enter summary"
              />
            </div> */}
            {/* <div class="form-outline">
            <label class="form-label" for="textAreaExample">Message</label>
              <textarea  value={summary} maxLength="50"
                onChange={(e) => setSummary(e.target.value)}  placeholder="Enter summary" class="form-control" id="textAreaExample1" rows="4"></textarea>
             
            </div> */}

            <label for="exampleInputPassword1">
              Caption<span style={{ color: "red" }}>*</span> :
            </label>

            <div style={{ marginBottom: "21px" }}>
              <CKEditor
                editor={ClassicEditor}
                data={caption}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  let data = editor.getData();

                  setCaption(data);
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            </div>
            <label for="exampleInputPassword1">
              Summary<span style={{ color: "red" }}>*</span> :
            </label>
            <div style={{ marginBottom: "21px" }}>
              <CKEditor
                editor={ClassicEditor}
                data={summary}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  console.log({ event, editor, data });
                  setSummary(data);
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            </div>

            <div class="mb-3">
              <label for="formFile" class="form-label">
                Upload Image<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                id="images"
                onChange={imageHandler}
                class="form-control"
                accept="image/*"
                type="file"
              />
              {imgLoader ? <ImageLoader /> : null}
              {image && (
                <>
                  <img
                    style={{
                      height: "30%",
                      width: "30%",
                      marginTop: "12px",
                      borderRadius: "9px",
                    }}
                    src={image}
                  />
                  <button
                    onClick={() => HandleCrossClick()}
                    style={{ color: "red" }}
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                  ></button>
                </>
              )}
            </div>

            <div class="form-group">
          <label for="exampleInputEmail1">
            Content<span style={{ color: "red" }}>*</span> :
          </label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Enter content"
          />
        </div>

            <button class="btn btn-primary" onClick={EditThematicCollection}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditThematicCollection;
