import React, { useState } from "react";
import HomeService from '../../Service/HomeService';
import HttpClientXml from "../../Utils/HttpClientXml";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";

const EditCollectionType = () => {
  const [name , setName] = useState('');
  const [description , setDescription] = useState('');
  const [image , setImage] = useState('');
  const [collectionCategoryId , setCategoryCollectionId] = useState('');

  const [AllCatData , setAllCatData] = useState([]);
  const [id , setId] = useState('')
  const location = useLocation()

console.log("LOC", location.state);

  useEffect(() => {
      fetchAllCollCatg();
      if(location.pathname == '/edit-collection-type'){
        setId(location?.state?.id)
        setCategoryCollectionId(location?.state?.collCatId);
        setName(location.state.collCatType)
      }
    }, []);

    const fetchAllCollCatg = () => {
      HomeService.AllCollectionCategory()
        .then((res) => {
          if (res && res?.status) {
              setAllCatData(res?.data)
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    };




const EditCollType = () => {

  let data = {
      collectionCategoryId: collectionCategoryId,
      name: name
  }

  console.log("Data" , data);
  if(name && collectionCategoryId){
    HomeService.DeleteCollType(id,data)
    .then((res) => {
        console.log("Response Edit" , res);
        if(res && res.status){
            toast.success("Update Successfully");
            setName("");
            setCategoryCollectionId("");
            
        }
    })
    .catch((err) => {
        console.log(err);
    })
  }else{
    toast.error("All Fields Are Required")
  }


}

const handleCollCat = (e) => {
  setCategoryCollectionId(e.target.value);
}

  return (
    <>
              <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div style={{ textAlign: "center" , fontSize: "20px" , color:'#868e96',  margin:'35px'}} className="card-title">
              Edit Collection Type
            </div>
            <label for="exampleInputEmail1">Collection Category :</label>
            <select
          style={{ marginBottom: "21px" }}
          class="form-select"
          aria-label="select category"
          value={collectionCategoryId}
          onChange={(e) => handleCollCat(e)}
        >
          <option value={""}>Select a collection category...</option>
          {AllCatData.map((item) => {
            return (
              <option id={item?._id} value={item?._id}>
                {item?.name}
              </option>
            );
          })}
        </select>
            <div class="form-group">
              <label for="exampleInputEmail1">Collection Type :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter collection type..."
              />
            </div>

            <button  class="btn btn-primary" onClick={EditCollType}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditCollectionType