import HttpClientXml from "../Utils/HttpClientXml";


async function register(data) {
    let endpoint = 'user/register';
    return HttpClientXml.post(endpoint, data);
  }

async function login(data){
  let endpoint = "login";
  return HttpClientXml.post(endpoint,data)
}

async function getProfile (){
  let endpoint = "user/getProfile"
  return HttpClientXml.get(endpoint)
}

async function changepass (data){
  let endpoint = "user/passwordChange"
  return HttpClientXml.put(endpoint,data)
}

  export default {
    register,
    login,
    getProfile,
    changepass
  }