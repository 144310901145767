import HttpClientXml from "../Utils/HttpClientXml";

// async function addbettingdata(data) {
//     let endpoint = 'others/add-betting-data';
//     return HttpClientXml.post(endpoint, data);
//   }

// async function getbettingdata (id){
//   let endpoint = "others/get-betting-data/"+id
//   return HttpClientXml.get(endpoint)
// }
// async function getbettingdatabyuser (){
//   let endpoint = "others/get-all-bet-by-user"
//   return HttpClientXml.get(endpoint)
// }
// async function totalProfitLoss (){
//   let endpoint = "others/profit-loss-per-game"
//   return HttpClientXml.get(endpoint)
// }
// async function ProfitLossbymatch (){
//   let endpoint = "others/per-user-bet"
//   return HttpClientXml.get(endpoint)
// }
// async function ProfitLossbybet (id){
//   let endpoint = "others/each-bet-details/"+id
//   return HttpClientXml.get(endpoint)
// }
// async function transactionList (){
//   let endpoint = "others/transaction-list"
//   return HttpClientXml.get(endpoint)
// }

//                        Collection Data

async function AddCollectionCatg(data) {
  let endpoint = "add-collection-category";
  return HttpClientXml.post(endpoint, data);
}

async function AllCollectionCategory() {
  let endpoint = "view-collection-category";
  return HttpClientXml.get(endpoint);
}

async function DeleteCollectionCategory(id, data) {
  let endpoint = `update-collection-category/${id}`;
  return HttpClientXml.P(endpoint, data);
}

async function EditCollectionCategory(id, data) {
  let endpoint = `update-collection-category/${id}`;
  return HttpClientXml.put(endpoint, data);
}

async function AddCollType(data) {
  let endpoint = "add-collection-type";
  return HttpClientXml.post(endpoint, data);
}

async function getAllCollType() {
  let endpoint = "view-collection-type";
  return HttpClientXml.get(endpoint);
}

async function DeleteCollType(id, data) {
  let endpoint = `update-collection-type/${id}`;
  return HttpClientXml.put(endpoint, data);
}

async function AddCollection(data) {
  let endpoind = "add-collection";
  return HttpClientXml.post(endpoind, data);
}

async function getAllCollection() {
  let endpoind = "view-collection";
  return HttpClientXml.get(endpoind);
}

async function UpdateCollection(id, data) {
  let endpoind = `update-collection/${id}`;
  return HttpClientXml.put(endpoind, data);
}

async function DeleteCollection(id, data) {
  let endpoind = `update-collection/${id}`;
  return HttpClientXml.put(endpoind, data);
}

async function AddPhotography(data) {
  let endpoind = "add-photo-category";
  return HttpClientXml.post(endpoind, data);
}

async function getAllPhotography(id) {
  let endpoind = `view-Photo-category/${id}`;
  return HttpClientXml.get(endpoind);
}

async function updatePhotoCategory(id, data) {
  let endpoind = `update-Photo-category/${id}`;
  return HttpClientXml.put(endpoind, data);
}

async function AddPhotoGallery(data) {
  let endpoind = "add-photo-gallery";
  return HttpClientXml.post(endpoind, data);
}

async function AddThematicCollection(data) {
  let endpoind = "add-thematic";
  return HttpClientXml.post(endpoind, data);
}

async function AllThematicCollection() {
  let endpoind = "view-all-thematic";
  return HttpClientXml.get(endpoind);
}

async function DeleteThematicCollection(id) {
  let endpoind = `delete-thematic/${id}`;
  return HttpClientXml.deletemethod(endpoind);
}

async function EditThematicCollection(id, data) {
  let endpoind = `update-thematic/${id}`;
  return HttpClientXml.put(endpoind, data);
}

async function fetchAllPhotoGallerys(id) {
  let endpoind = `view-Photo-gallery/${id}`;
  return HttpClientXml.get(endpoind);
}

async function UpdatePhotogallery(id, data) {
  let endpoind = `update-Photo-gallery/${id}`;
  return HttpClientXml.put(endpoind, data);
}

async function AddBlog(data) {
  let endpoind = "add-blog";
  return HttpClientXml.post(endpoind, data);
}
async function ManageBlog() {
  let endpoind = "view-all-blog";
  return HttpClientXml.get(endpoind);
}
async function UpdateBlog(id, data) {
  let endpoind = `update-blog/${id}`;
  return HttpClientXml.put(endpoind, data);
}
async function DeleteBlog(id) {
  let endpoind = `delete-blog/${id}`;
  return HttpClientXml.deletemethod(endpoind);
}

async function ViewAllComments() {
  let endpoint = "view-all-comment";
  return HttpClientXml.get(endpoint);
}

async function IsApproved(id) {
  let endpoint = `approved-comments/${id}`;
  return HttpClientXml.put(endpoint);
}

async function addBlogCategory(data) {
  let endpoind = "add-blog-category";
  return HttpClientXml.post(endpoind, data);
}

async function ViewAllBlogCategory() {
  let endpoind = "view-all-blogCategory";
  return HttpClientXml.get(endpoind);
}

async function UpdateBlogCategory(id, data) {
  let endpoind = `update-blog-category/${id}`;
  return HttpClientXml.put(endpoind, data);
}

async function DeleteBlogCategory(id) {
  let endpoind = `delete-blog-category/${id}`;
  return HttpClientXml.deletemethod(endpoind);
}

async function ViewParticularThematic(id) {
  let endpoind = `view-particular-edu-statup-collection/${id}`;
  return HttpClientXml.get(endpoind);
}

async function UpdateThematic(id, data) {
  let endpoind = `update-edu-startup-collection/${id}`;
  return HttpClientXml.put(endpoind, data);
}

async function AddStartupsType(data) {
  let endpoind = "add-thematic";
  return HttpClientXml.post(endpoind, data);
}

async function ViewAllThematicType(data) {
  let endpoind = "view-edu-startup-collection";
  return HttpClientXml.post(endpoind, data);
}

async function EditThematic(id, data) {
  let endpoind = `update-edu-startup-collection/${id}`;
  return HttpClientXml.put(endpoind, data);
}

async function DeleteThematic(id) {
  let endpoind = `delete-edu-statup-collection/${id}`;
  return HttpClientXml.deletemethod(endpoind);
}

async function ViewAllThematic() {
  let endpoint = "view-all-thematic";
  return HttpClientXml.get(endpoint);
}

async function UpdateThematics(id, data) {
  let endpoind = `update-thematic/${id}`;
  return HttpClientXml.put(endpoind, data);
}

async function ViewBannerCollection() {
  let endpoint = "view-banner-collection";
  return HttpClientXml.get(endpoint);
}

async function UpdateBannerCollection(id, data) {
  let endpoind = `update-banner-collection/${id}`;
  return HttpClientXml.put(endpoind, data);
}

async function ViewAllRegisterList() {
  let endpoind = "view-all-register-list";
  return HttpClientXml.get(endpoind);
}

async function ApprovedRegister(id) {
  let endpoind = `approved-register/${id}`;
  return HttpClientXml.put(endpoind);
}

async function ViewAdminProfile() {
  let endpoind = "view-profile";
  return HttpClientXml.get(endpoind);
}

async function EditProfile(data) {
  let endpoind = "edit-profile";
  return HttpClientXml.put(endpoind, data);
}

async function AddEducationType(data) {
  let endpoind = "add-edu-startp-type";
  return HttpClientXml.post(endpoind, data);
}

async function ChangePassword(data) {
  let endpoind = "change-password";
  return HttpClientXml.put(endpoind, data);
}

async function viewEduStartupType(id) {
  let endpoind = `view-education-type/${id}`;
  return HttpClientXml.get(endpoind);
}

async function UpdateEduStartup(id, data) {
  let endpoind = `update-edu-startup-type/${id}`;
  return HttpClientXml.put(endpoind, data);
}
async function DeleteEduStartup(id) {
  let endpoind = `delete-type/${id}`;
  return HttpClientXml.deletemethod(endpoind);
}

async function deleteRegisteredUser(id) {
  let endpoind = `admin-delete-user/${id}`;
  return HttpClientXml.put(endpoind);
}

async function ContributingPartnersLogo() {
  let endpoind = "logo";
  return HttpClientXml.get(endpoind);
}

async function AddContributingPartnersLogo(data) {
  let endpoind = "logo";
  return HttpClientXml.post(endpoind, data);
}

async function DeleteContributingPartnersLogo(id) {
  let endpoind = `logo/${id}`;
  return HttpClientXml.deletemethod(endpoind);
}

async function DeleteComment(id) {
  let endpoind = `delete-comment/${id}`;
  return HttpClientXml.put(endpoind);
}

///
async function AddFolklore(data) {
  let endpoint = "folklore-data";
  return HttpClientXml.post(endpoint, data);
}
///
async function AllFolkloreData() {
  let endpoint = "folklore-get";
  return HttpClientXml.get(endpoint);
}
///
async function EditFolklore(id, data) {
  let endpoint = `folklore-update/${id}`;
  return HttpClientXml.put(endpoint, data);
}
///
async function DeleteFolklore(id, data) {
  let endpoint = `folklore-delete/${id}`;
  return HttpClientXml.put(endpoint, data);
}

//               DESCRIPTION

async function CollectionDescription() {
  let endpoind = "view-collectiondesc";
  return HttpClientXml.get(endpoind);
}
async function ThematicDescription() {
  let endpoind = "view-thematicdesc";
  return HttpClientXml.get(endpoind);
}
async function BlogDescription() {
  let endpoind = "view-blogdesc";
  return HttpClientXml.get(endpoind);
}
async function FlokloreDescription() {
  let endpoind = "view-flokdesc";
  return HttpClientXml.get(endpoind);
}

  // Add DESCRIPTION

  async function AddCollectionDesc(data) {
    let endpoind = "add-collectiondesc";
    return HttpClientXml.post(endpoind, data);
  }
  async function AddThematicDescription(data) {
    let endpoind = "add-thematicdesc";
    return HttpClientXml.post(endpoind, data);
  }
  async function AddBlogDescription(data) {
    let endpoind = "add-blogdesc";
    return HttpClientXml.post(endpoind, data);
  }
  async function AddFlokloreDescription(data) {
    let endpoind = "add-flokdesc";
    return HttpClientXml.post(endpoind, data);
  }

  async function viewFolklorecollection() {
    let endpoind = "view-folklorecollection";
    return HttpClientXml.get(endpoind);
  }

  

  async function Addfolklorecollection(data) {
    let endpoind = "add-folklorecollection";
    return HttpClientXml.post(endpoind, data);
  }
  async function updatefolklorecollection(id , data) {
    let endpoind = `update-folklorecollection/${id}`;
    return HttpClientXml.put(endpoind, data);
  }
  

  async function DeleteFolkloreCollection(id) {
    let endpoind = `delete-folklorecollection/${id}`;
    return HttpClientXml.deletemethod(endpoind);
  }


  

  


export default {
  Addfolklorecollection,
  updatefolklorecollection,
  DeleteFolkloreCollection,
  viewFolklorecollection,
  CollectionDescription,
  AddThematicDescription,
  AddCollectionDesc,
  AddBlogDescription,
  AddFlokloreDescription,
  ThematicDescription,
  BlogDescription,
  AddCollectionCatg,
  FlokloreDescription,
  AllCollectionCategory,
  DeleteCollectionCategory,
  EditCollectionCategory,
  AddCollType,
  getAllCollType,
  DeleteCollType,
  AddCollection,
  getAllCollection,
  DeleteCollection,
  UpdateCollection,
  AddPhotography,
  getAllPhotography,
  updatePhotoCategory,
  AddPhotoGallery,
  AddThematicCollection,
  AllThematicCollection,
  DeleteThematicCollection,
  EditThematicCollection,
  fetchAllPhotoGallerys,
  UpdatePhotogallery,
  AddBlog,
  ManageBlog,
  UpdateBlog,
  DeleteBlog,
  ViewAllComments,
  IsApproved,
  addBlogCategory,
  ViewAllBlogCategory,
  UpdateBlogCategory,
  DeleteBlogCategory,
  ViewParticularThematic,
  UpdateThematic,
  AddStartupsType,
  ViewAllThematicType,
  EditThematic,
  DeleteThematic,
  ViewAllThematic,
  UpdateThematics,
  ViewBannerCollection,
  UpdateBannerCollection,
  ApprovedRegister,
  ViewAllRegisterList,
  ViewAdminProfile,
  EditProfile,
  AddEducationType,
  ChangePassword,
  viewEduStartupType,
  UpdateEduStartup,
  DeleteEduStartup,
  deleteRegisteredUser,
  ContributingPartnersLogo,
  AddContributingPartnersLogo,
  DeleteContributingPartnersLogo,
  DeleteComment,
  AddFolklore,
  AllFolkloreData,
  EditFolklore,
  DeleteFolklore,
  // UploadImage
};
