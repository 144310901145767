import React, { useState } from "react";
import HomeService from "../../Service/HomeService";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { error } from "jquery";

function ChangePasswordForm() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswords, setShowPasswords] = useState(false);

  const OnPasswordChange = (event) => {
    // console.log("AAAAAAAAAAA");
    event.preventDefault();

    let data = {
      oldpass: currentPassword,
      newpass: newPassword,
    };

    console.log("DATA", data);

    if (currentPassword && newPassword && newPassword.length >= 6) {
      HomeService.ChangePassword(data)
        .then((res) => {
          console.log("Response Change Password", res);
          if (res && res.status) {
            toast.success(" Your Password Has Been Changed Successfully");

            setCurrentPassword("");
            setNewPassword("");
            navigate("/");
          } else {
            console.log("ERRRRR");
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          console.log("MMMMMMM");

          console.log(err);
        });
    } else {
      if(currentPassword == "" || newPassword == ""){
        toast.error("All Fields Are Required");
        return
      }
      if (newPassword.length < 6) {
        toast.error("password length must be greater than 5");
        return;
      }
      
    }
  };

  return (
    <>
      <h2 style={{ textAlign: "center" }}>Change Password</h2>

      <div className="container">
        <div className="d-flex">
          <div className="form-group">
            <label style={{ marginLeft: "9px" }} for="exampleInputEmail1">
              Old Password<span style={{ color: "red" }}>*</span> :
            </label>
            <input
              // type="password"
              type={showPassword ? "text" : "password"}
              class="form-control"
              id="exampleInputEmail1"
              className="text-field"
              aria-describedby="emailHelp"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Enter old password..."
            />
          </div>
          <button
            style={{
              padding: "6px",
              border: "none",
              margin: "35px -23px",
            }}
            className="eyecon"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FaEye /> : <FaEyeSlash />}
          </button>
        </div>

        <div className="d-flex">
          <div className="form-group">
            <label style={{ marginLeft: "9px" }} for="exampleInputEmail1">
              New Password<span style={{ color: "red" }}>*</span> :
            </label>

            <input
              // type="password"
              type={showPasswords ? "text" : "password"}
              class="form-control"
              id="exampleInputEmail1"
              className="text-field"
              aria-describedby="emailHelp"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter new password..."
            />
          </div>
          <button
            style={{
              padding: "6px",
              border: "none",
              margin: "35px -23px",
            }}
            className="eyecon"
            onClick={() => setShowPasswords(!showPasswords)}
          >
            {showPasswords ? <FaEye /> : <FaEyeSlash />}
          </button>
        </div>

        <button className="submit-button" onClick={(e) => OnPasswordChange(e)}>
          Change Password
        </button>
      </div>
    </>
  );
}

export default ChangePasswordForm;
