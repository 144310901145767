import React, { useState, useSyncExternalStore } from "react";
import HomeService from "../../Service/HomeService";
import HttpClientXml from "../../Utils/HttpClientXml";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SelectableInput from "../../Component/SelectableInput";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import DatePicker from "react-datepicker";

const EditCollection = () => {
  const [hide, setHide] = useState(true);
  const location = useLocation();
  const [content , setContent] = useState("");

  const [dateType, setDateType] = useState("");

  const [publishDate, setpublishDate] = useState("");

  const [image, setImage] = useState("");
  const [img, setImg] = useState("");
  const [collectionCategoryId, setCategoryCollectionId] = useState("");

  const [themeCollection, setThemeCollection] = useState("");
  const [catTypeId, setCatTypeId] = useState("");
  console.log("ddddddddddd", catTypeId);

  const [imgs, setImgs] = useState("");

  const [AllCatData, setAllCatData] = useState([]);
  const [catType, setCatType] = useState([]);
  console.log("catTypettttttt", catType);

  const [title, setTitle] = useState("");
  const [summery, setSummery] = useState("");
  const [author, setAuthor] = useState("");
  const [desc, setDesc] = useState("");
  const [partner, setPartner] = useState("");
  const [contribution_institution, setContribution_institution] = useState("");
  const [subject, setSubject] = useState("");
  const [url, setUrl] = useState("");
  const [license, setLicense] = useState("");
  const [typeName, setTypeName] = useState("");
  const [hides, setHides] = useState(false);
  const [hide1, setHide1] = useState(false);

  const [hide2, setHide2] = useState(false);
  const [hide3, setHide3] = useState(false);

  console.log("TypeName", typeName);

  const [fiction, setFiction] = useState(Boolean);
  const [date, setDate] = useState("");

  const [collectionUrl, setCollectionUrl] = useState("");

  const [id, setId] = useState("");
  const navigate = useNavigate();

  const [allthemeCollection, setAllThemeCollection] = useState([]);

  const [isFeatured, setIsFeatured] = useState(false);
  const [searchTag, setSearchTag] = useState([]);

  useEffect(() => {
    if (location?.pathname == "/edit-collection") {
      console.log("LOCATIONS", location?.state);
      setId(location?.state?._id);
      setCategoryCollectionId(location?.state?.category?._id);
      setThemeCollection(location?.state?.themeData[0]?._id);
      setContent(location?.state?.content);
      setCollectionUrl(location?.state?.link);
      setTitle(location?.state?.title);
      setImage(location?.state?.uploadCollection);
      setImg(location?.state?.image);
      setImgs(location?.state?.collectionImage);
      setSummery(location?.state?.summery);
      setAuthor(location?.state?.author);
      setDesc(location?.state?.desc);
      setPartner(location?.state?.partner);
      setContribution_institution(location?.state?.contribution_institution);
      setSubject(location?.state?.subject);
      setUrl(location?.state?.url);
      setLicense(location?.state?.license);
      setTypeName(location?.state?.type);
      setSearchTag(location?.state?.searchTag);
      setIsFeatured(location?.state?.isFeatured);
      // setDate(moment(location?.state?.publishDate).format("YYYY-MM-DD"));
      setFiction(location?.state?.isFiction);

      if (location.state.publishDate) {
        let datecheking = location?.state.publishDate;
        // if (location.state.publishDate) {
        let yearvalidate = /^([0-9]{4})$/;
        let month = /^([0-9]{4})\-([0-9]{2})$/;
        let date = /^([0-9]{4})\-([0-9]{2})\-([0-9]{2})$/;

        // }
        console.log("test", month.test(datecheking));

        if (yearvalidate.test(datecheking)) {
          setDateType("year");
          setpublishDate(datecheking);
        }
        if (month.test(datecheking)) {
          setDateType("month");
          setpublishDate(datecheking);
        }
        if (date.test(datecheking)) {
          setDateType("date");
          setpublishDate(datecheking);
        }
      }

      if (location?.state?.url) {
        setHide2(true);
      } else {
        setHide3(true);
      }

      if (location?.state?.link) {
        setHide1(true);
      } else {
        setHides(true);
      }

      // setCollectionUrl(location?.state?.link)
    }
    fetchAllCollCatg();
    fetchThemeCollection();
  }, []);
  console.log("themeCollection", themeCollection);

  const fetchAllCollCatg = () => {
    HomeService.AllCollectionCategory()
      .then((res) => {
        if (res && res?.status) {
          setAllCatData(res?.data);
        }
        console.log("RESCAT", res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const renderYearContent = (year) => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };

  const fetchCategoryType = () => {
    let arr = [];
    HomeService.getAllCollType()
      .then((res) => {
        if (res && res.status) {
          res?.data.forEach((element) => {
            arr.push(...element.categoryType);
          });
          setCatType(arr);
        }
        console.log("AAAAAAAAAAAAA", arr);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchThemeCollection = () => {
    HomeService.AllThematicCollection()
      .then((res) => {
        if (res && res?.status) {
          setAllThemeCollection(res?.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const EditCollections = () => {
    let data = {
      catId: collectionCategoryId,
      type: typeName,
      themeId: themeCollection,
      title: title,
      uploadCollection: image,
      image: img,
      collectionImage: imgs,
      link: collectionUrl,
      summery: summery.replace("<p>", "").replace("</p>", ""),
      author: author,
      desc: desc,
      partner: partner,
      contribution_institution: contribution_institution,
      subject: subject,
      url: url,
      license: license,
      searchTag: searchTag,
      isFiction: fiction,
      content: content,
      isFeatured,
      publishDate: publishDate,
    };

    console.log("Data", data);
    if (
      collectionCategoryId &&
      // themeCollection &&
      title &&
      // image &&
      // img &&
      // imgs &&
      summery
      // author &&
      //  searchTag.length !== 0
      // desc &&
      // partner &&
      // contribution_institution &&
      // subject &&
      // url &&
      // license
    ) {
      if (!searchTag.length) {
        toast.error("Press enter after write each tag ");
        return;
      }
      if (new Date(publishDate) > new Date()) {
        toast.error(
          "Publish date must not be Future Date……It must be either Past date or Current Date"
        );
        return;
      }
      HomeService.UpdateCollection(id, data)
        .then((res) => {
          console.log("Response Edit Collection", res);
          if (res && res.status) {
            toast.success(res.message);
            setCategoryCollectionId("");
            setThemeCollection("");
            setTitle("");
            setImage("");
            setImg("");
            setSummery("");
            setCollectionUrl("");
            setAuthor("");
            setDesc("");
            setContent("")
            setPartner("");
            setContribution_institution("");
            setSubject("");
            setUrl("");
            setLicense("");
            // let file = document.querySelector("#images");
            // file.value = "";
            let files = document.querySelector("#image");
            files.value = "";
            setImgs("");
            let file = document.querySelector("#img");
            file.value = "";

            setIsFeatured(false);
            setSearchTag([]);

            navigate("/manage-collection");
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Mandatory fields are required");
    }
  };

  const handleCollCatName = (e) => {
    setCategoryCollectionId(e.target.value);
    setHide(false);
  };

  const imageHandler = async (e) => {
    setHides(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("doc", file);

    let res = await HttpClientXml.fileUplode(
      "upload-collection-document",
      "POST",
      data
    );
    if (res && res.status) {
      toast.success("Uploaded Successfully");
      setImage(res?.doc?.url) ||
        setImage(`http://13.127.222.180:3056/${res?.image}`);
    } else {
      toast.error("Failed to upload Image");
    }
  };

  const imageHandlers = async (e) => {
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);

    let res = await HttpClientXml.fileUplode(
      "upload-Photocategory",
      "POST",
      data
    );
    if (res && res.status) {
      toast.success("Image uploaded Successfully");
      setImg(res?.doc?.url);
    } else {
      toast.error("Failed to upload Image");
    }
  };

  const HandleCrossClickss = () => {
    setImgs("");
    let file = document.querySelector("#img");
    file.value = "";
  };

  const handlethemeCollection = (e) => {
    setThemeCollection(e.target.value);
  };

  const HandleFiction = (e) => {
    if (e.target.checked) {
      setFiction(true);
    }
  };

  const HandleFiction1 = (e) => {
    if (e.target.checked) {
      setFiction(false);
    }
  };

  const HandleCrossClick = () => {
    setHides(false);
    setHide3(false);
    setImage("");
    let file = document.querySelector("#images");
    file.value = "";
  };

  const collectionUrls = (e) => {
    e.target.value ? setHide1(true) : setHide1(false);
    setCollectionUrl(e.target.value);
  };

  const imageHandlerss = async (e) => {
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);

    let res = await HttpClientXml.fileUplode(
      "upload-Photocategory",
      "POST",
      data
    );
    if (res && res.status) {
      toast.success(" Uploaded Successfully");
      setImgs(res?.doc?.url);
    } else {
      toast.error("Failed to upload Image");
    }
  };

  const HandleCrossClicks = () => {
    setImg("");
    let file = document.querySelector("#image");
    file.value = "";
  };

  const checkex = () => {
    if (typeName == "videos") {
      return "video/*";
    } else if (typeName == "audios") {
      return "audio/mp3";
    } else {
      return "application/pdf";
    }
  };

  return (
    <>
      <>
        <div className="card-body">
          <div
            style={{
              textAlign: "center",
              fontSize: "20px",
              color: "#868e96",
              margin: "35px",
            }}
            className="card-title"
          >
            Edit {typeName}
          </div>

          <label for="exampleInputEmail1">
            Collection Category<span style={{ color: "red" }}>*</span> :
          </label>

          <select
            style={{ marginBottom: "21px" }}
            class="form-select"
            aria-label="select category"
            value={collectionCategoryId}
            onChange={(e) => handleCollCatName(e)}
          >
            <option value={""}>Select a collection category.......</option>
            {AllCatData.map((item) => {
              return (
                <option id={item?._id} value={item?._id}>
                  {item?.name}
                </option>
              );
            })}
          </select>

          {typeName === "videos" ||
          typeName === "audios" ||
          typeName === "books" ||
          typeName === "documents" ? (
            <>
              <label for="exampleInputEmail1">Theme :</label>
              <select
                style={{ marginBottom: "21px" }}
                class="form-select"
                typeName
                onChange={(e) => handlethemeCollection(e)}
                value={themeCollection}
              >
                <option value={""}>Select a theme.......</option>
                {allthemeCollection.map((item) => {
                  return (
                    <option id={item?._id} value={item?._id}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            </>
          ) : null}

          <div class="form-group">
            <label for="exampleInputEmail1">
              Title<span style={{ color: "red" }}>*</span> :
            </label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter title"
            />
          </div>

          {typeName === "videos" || typeName === "audios" ? (
            <>
              <div class="form-group">
                <label for="exampleInputEmail1">Collection URL :</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={collectionUrl}
                  onChange={(e) => collectionUrls(e)}
                  placeholder="Enter Collection URL.."
                  disabled={hides}
                />
              </div>

              <div class="mb-3">
                <label for="formFile" class="form-label">
                  Upload Collection :
                </label>
                <input
                  id="images"
                  onChange={imageHandler}
                  class="form-control"
                  accept={checkex()}
                  type="file"
                  disabled={hide1}
                />
                {image && (
                  <>
                    <svg
                      style={{ height: "15%", width: "15%" }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="w-6 h-6"
                    >
                      <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625z" />
                      <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
                    </svg>
                    <button
                      onClick={() => HandleCrossClick()}
                      style={{ color: "red" }}
                      type="button"
                      class="btn-close"
                      aria-label="Close"
                    ></button>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div class="mb-3">
                <label for="formFile" class="form-label">
                  Upload Collections :
                </label>
                <input
                  id="images"
                  onChange={imageHandler}
                  class="form-control"
                  type="file"
                  disabled={hide2}
                  accept={checkex()}
                />
                {image && (
                  <>
                    <svg
                      style={{ height: "15%", width: "15%" }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="w-6 h-6"
                    >
                      <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625z" />
                      <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
                    </svg>
                    {/* /> */}
                    <button
                      onClick={() => HandleCrossClick()}
                      style={{ color: "red" }}
                      type="button"
                      class="btn-close"
                      aria-label="Close"
                    ></button>
                  </>
                )}
              </div>

              <div class="form-group">
                <label for="exampleInputEmail1">URL :</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={url}
                  disabled={hide3}
                  onChange={(e) => {
                    e.target.value === "" ? setHide2(false) : setHide2(true);
                    setUrl(e.target.value);
                  }}
                  placeholder="Enter URL"
                />
              </div>
            </>
          )}

          <div class="mb-3">
            <label for="formFile" class="form-label">
              Thumbnail Image :
            </label>
            <input
              id="image"
              onChange={imageHandlers}
              class="form-control"
              type="file"
              accept="image/*"
            />
            {img && (
              <>
                <img
                  style={{
                    height: "10%",
                    width: "10%",
                    marginTop: "12px",
                    borderRadius: "12px",
                  }}
                  src={img}
                />
                <button
                  onClick={() => HandleCrossClicks()}
                  style={{ color: "red" }}
                  type="button"
                  class="btn-close"
                  aria-label="Close"
                ></button>
              </>
            )}
          </div>

          <div class="mb-3">
            <label for="formFile" class="form-label">
              Collection Image :
            </label>
            <input
              id="img"
              onChange={imageHandlerss}
              class="form-control"
              type="file"
              accept="image/*"
            />
            {imgs && (
              <>
                <img
                  style={{
                    height: "10%",
                    width: "10%",
                    marginTop: "12px",
                    borderRadius: "12px",
                  }}
                  src={imgs}
                />
                <button
                  onClick={() => HandleCrossClickss()}
                  style={{ color: "red" }}
                  type="button"
                  class="btn-close"
                  aria-label="Close"
                ></button>
              </>
            )}
          </div>

          {/* <div class="form-group">
              <label for="exampleInputEmail1">Summary<span style={{ color:'red'}}>*</span> :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={summery}
                onChange={(e) => setSummery(e.target.value)}
                placeholder="Enter summary"
              />
            </div> */}

          <label for="exampleInputEmail1">
            Summary<span style={{ color: "red" }}>*</span> :
          </label>

          <div style={{ marginBottom: "21px" }}>
            <CKEditor
              editor={ClassicEditor}
              data={summery}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                // let arr = [];
                // let data = editor.getData();
                // let splits = data.split(/(\s+)/);
                // let words = splits.filter((x) => x.trim().length>0);
                // var count = words.length;
                // if(words.length < 50){
                setSummery(editor.getData());
                // }else{
                //   toast.error("Summary length should be less than 50 character");
                // }
              }}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Author :</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              placeholder="Enter author"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Description :</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              placeholder="Enter desc"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Partner :</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={partner}
              onChange={(e) => setPartner(e.target.value)}
              placeholder="Enter partner"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Contributing Institution :</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={contribution_institution}
              onChange={(e) => setContribution_institution(e.target.value)}
              placeholder="Enter Contributing Institution"
            />
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Subject :</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder="Enter subject"
            />
          </div>

          {typeName === "videos" || typeName === "audios" ? (
            <div class="form-group">
              <label for="exampleInputEmail1">URL :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Enter URL"
              />
            </div>
          ) : null}

          <div class="form-group">
            <label for="exampleInputEmail1">License / Right :</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={license}
              onChange={(e) => setLicense(e.target.value)}
              placeholder="Enter license"
            />
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Publish Date Type :</label>

            <select
              style={{ marginBottom: "21px" }}
              class="form-select"
              aria-label="select category"
              value={dateType}
              onChange={(e) => {
                setpublishDate("");
                setDateType(e.target.value);
              }}
            >
              <option value={""}>Select a publish date type.......</option>
              {["year", "month", "date"].map((item) => {
                return <option value={item}>{item}</option>;
              })}
            </select>
          </div>

          {dateType === "year" && (
            <div style={{ marginBottom: "12px" }}>
              <label for="exampleInputEmail1"> Select Year :</label>
              <DatePicker
                className="form-control"
                selected={new Date(publishDate, 0)}
                // selected={ }s
                // value={publishDate}
                // value={publishDate}
                renderYearContent={renderYearContent}
                showYearPicker
                onChange={(year) => {
                  console.log("ssssssss", moment(year).year());
                  // setpublishDate(moment(year).year());
                  setpublishDate(moment(year).year());
                }}
                dateFormat="yyyy"
              />
            </div>
          )}

          {dateType === "month" && (
            <div style={{ marginBottom: "12px" }}>
              <label for="exampleInputEmail1">Select Month & Year :</label>
              <input
                className="form-control"
                type="month"
                value={publishDate}
                onChange={(e) => {
                  setpublishDate(e.target.value);
                }}
              />
            </div>
          )}

          {dateType === "date" && (
            <div style={{ marginBottom: "12px" }}>
              <label for="exampleInputEmail1">Select Full Date :</label>
              <input
                className="form-control"
                type="date"
                value={publishDate}
                onChange={(e) => {
                  setpublishDate(e.target.value);
                }}
              />
            </div>
          )}

          {typeName === "books" ? (
            <>
              <label for="exampleInputEmail1">SubCategory :</label>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  value={fiction}
                  id="flexRadioDefault1"
                  onChange={(e) => HandleFiction(e)}
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  Fiction
                </label>
              </div>
              <div class="form-check" style={{ marginBottom: "34px" }}>
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  value={fiction}
                  id="flexRadioDefault2"
                  onChange={(e) => HandleFiction1(e)}
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  Non Fiction
                </label>
              </div>
            </>
          ) : null}

          <div className="form-group">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue=""
                id="featured"
                checked={isFeatured}
                onChange={(val) => setIsFeatured(val.target.checked)}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Featured
              </label>
            </div>
          </div>
          <SelectableInput
            title={`Add ${typeName} Search Tag`}
            value={searchTag}
            onChange={(val) => setSearchTag(val)}
            className="form-group"
            placeholder={`Enter keyword(s) to search ${typeName}`}
          />

        <div class="form-group">
          <label for="exampleInputEmail1">
            Content<span style={{ color: "red" }}>*</span> :
          </label>
          <input
            type="text"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Enter content"
          />
        </div>

          <button
            onClick={() => {
              EditCollections();
            }}
            type="button"
            class="btn btn-warning"
          >
            Update
          </button>
        </div>
      </>
    </>
  );
};

export default EditCollection;
