import React, { useState } from "react";
import HomeService from "../../Service/HomeService";
import HttpClientXml from "../../Utils/HttpClientXml";
import { toast } from "react-hot-toast";
import ImageLoader from "../../Loader/ImageLoader";
import { CheckFileDimension } from "../CheckFileDimension";

const AddCollectionCategory = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  console.log(description);
  const [image, setImage] = useState("");
  const [imageLoader, setImageLoader] = useState(false);
  console.log("Image", image);

  const imageHandler = async (e) => {
    toast.success("Please upload images with a height of 260 pixels and a width of 300 pixels.")
    console.log("IMAGESIZE", e);
            setImageLoader(true);
            let file = e.target.files[0];
            let data = new FormData();
            data.append("image", file);

            let res = await HttpClientXml.fileUplode(
              "upload-Photocategory",
              "POST",
              data
            );
            if (res && res.status) {
              toast.success("Image uploaded successfully");

              setImage(res?.doc?.url);
              setImageLoader(false);
            } else {
              setImageLoader(false);
              toast.error("Failed to upload Image");
            }
  }
  

  const AddCollCategory = () => {
    let data = {
      name: name,
      desc: description,
      image: image,
    };
    if (name && description && image) {
      HomeService.AddCollectionCatg(data)
        .then((res) => {
          console.log("Response Add", res);
          if (res && res.status) {
            toast.success("Collection Category added successfully");
            setName("");
            setDescription("");
            let file = document.querySelector("#images");
            file.value = "";
            setImage("");
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("All Fields Are Required");
    }
  };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Add Collection Category
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">
                Name<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter name..."
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">
                Description<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Enter description..."
              />
            </div>

            <div class="mb-3">
              <label for="formFile" class="form-label">
                Upload Image<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                id="images"
                onChange={imageHandler}
                class="form-control"
                accept="image/*"
                type="file"
              />
              {imageLoader ? (
                <>
                  <ImageLoader />{" "}
                </>
              ) : null}
              {image && (
                <img
                  style={{
                    height: "30%",
                    width: "30%",
                    marginTop: "12px",
                    borderRadius: "9px",
                  }}
                  src={image}
                />
              )}
            </div>

            <button class="btn btn-primary" onClick={AddCollCategory}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCollectionCategory;
