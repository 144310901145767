import React, { useEffect, useState } from "react";
import $ from "jquery";
import Sidebar from "./Sidebar";
import { useDispatch } from "react-redux";
import { removeUser } from "../Redux/Reducer/User";
import { useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import HomeService from "../Service/HomeService";
import Swal from "sweetalert2";
import { toast } from "react-hot-toast";

export default function Header() {
  const [accountModal, setAccountModal] = useState(false);
  const [model, setModel] = useState(false);

  const [name , setName] = useState("");
  const [AdminName , setAdminName] = useState('');
  const [email , setEmail] = useState("");
  const [hide , setHide] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hankclickModal = () => {
    setAccountModal(!accountModal);
  };


  useEffect(() => {
    HomeService.ViewAdminProfile()
       
      .then((res) => {
          console.log("Response Profile" , res);
          if(res && res.status){
              setName(res?.data?.name);
              setEmail(res?.data?.email)
          }else{
            console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEE");
            toast.error(res?.error)
          }
      })
      .catch((err) => {
        console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEE");
          console.log(err);
      })
  })

  const drawyer = () => {
    $(".app-sidebar").toggleClass("closed-sidebar");
    $(".app-main__outer").toggleClass("test");
    $(".app-footer__inner").toggleClass("test3");
  };
  const drawyerMob = () => {
    $(".app-sidebar").toggleClass("sidebarmob");
    $(".sidebar-mobile-overlay").toggleClass("sidebar-mobile-open");
    $(".BurgerBox").toggleClass("BurgerActive");
  };

  const logouthandeler = () => {
    Swal.fire({
      title: "Are you sure?",
      // text: "Do you really want to log out ?",
      icon: "warning",
      showCancelButton: true,
      // showNoButton:true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        reactLocalStorage.remove("loginStatus");
        reactLocalStorage.clear("token");
        navigate("/login");
      }
    });



    // const swalWithBootstrapButtons = Swal.mixin({
    //   customClass: {
    //     confirmButton: 'btn btn-success',
    //     cancelButton: 'btn btn-danger'
    //   },
    //   buttonsStyling: false
    // })

    // swalWithBootstrapButtons.fire({
    //   title: 'Are you sure?',
    //   // text: "You won't be able to revert this!",
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonText: 'Yes',
    //   confirmButtonAriaLabel:"5px",
    //   cancelButtonText: 'No',
    //   reverseButtons: false
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     if (result.isConfirmed) {
    //       reactLocalStorage.remove("loginStatus");
    //       reactLocalStorage.clear("token")
    //       navigate('/login')
    //     }
    //   }
    // })
  };


  const profileUpdate = () => {
    console.log("JGSHDFJSDHJS DHD");
    let data = {
      name: AdminName
    }
    console.log("DATA" , data);
    HomeService.EditProfile(data)
    .then((res) => {
      console.log("RESEDIT" , res);
      if(res && res?.status){
        toast.success("Admin profile updated successfully");
        setHide(false)
      }else{
        toast.error(res?.message)
      }
    })
  }


  return (
    <>
      <Sidebar />
      <div className="app-header bg-strong-bliss header-text-light header-shadow appear-done enter-done">
        <div className="app-header__logo">
          {/* <div className="" /> */}
          <div className="logo_" style={{ color: "red" }}>
            <span style={{ fontWeight: "bold" }}>DIGITAL</span> Library Nagaland
          </div>
          {/* <img src='https://assets.zyrosite.com/cdn-cgi/image/format=a…=95/AVLDKzRX1VIVzR2l/logo111-dJoGKbeaXwu96pMP.png'/> */}
          <div className="header__pane ms-auto">
            <div>
              <button
                className="jsx-2200192874 BurgerSlider Burger"
                onClick={drawyer}
              >
                <div className="jsx-2200192874 BurgerBox">
                  <div className="jsx-2200192874 BurgerInner" />
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="app-header__mobile-menu">
          <div>
            <button
              className="jsx-3928250682 BurgerSlider Burger"
              onClick={drawyerMob}
            >
              <div className="jsx-3928250682 BurgerBox">
                <div className="jsx-3928250682 BurgerInner" />
              </div>
            </button>
          </div>
        </div>

        <div className="app-header__content">
          {/* <div className="app-header-left">
            <div className="search-wrapper">
              <div className="input-holder">
                <input
                  type="text"
                  className="search-input"
                  placeholder="Type to search"
                />
                <button className="search-icon">
                  <span />
                </button>
              </div>
              <button className="btn-close" />
            </div>
          </div> */}
          <div className="app-header-right">
            <div className="header-btn-lg pe-0">
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left">
                    
                    <div className="btn-group">
                      <button
                        type="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="p-0 btn btn-link"
                        onClick={hankclickModal}
                      >
                        <img
                          width={42}
                          className="rounded-circle"
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"
                          alt=""
                        />
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="angle-down"
                          className="svg-inline--fa fa-angle-down ms-2 opacity-8"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          style={{ height: "1em" }}
                        >
                          <path
                            fill="currentColor"
                            d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                          />
                        </svg>
                      </button>
                      <div
                        tabIndex={-1}
                        role="menu"
                        aria-hidden="true"
                        className="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-end"
                      >
                        <ul className="nav flex-column">
                          <li className="nav-item-header nav-item">Activity</li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Chat
                              <div className="ms-auto badge bg-pill bg-info">
                                8
                              </div>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Recover Password
                            </a>
                          </li>
                          <li className="nav-item-header nav-item">
                            My Account
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Settings
                              <div className="ms-auto badge bg-success">
                                New
                              </div>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Messages
                              <div className="ms-auto badge bg-warning">
                                512
                              </div>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Logs
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="widget-content-left  ms-3 header-user-info">
                    <div className="widget-heading">Admin</div>
                    {/* <div className="widget-subheading">VP People Manager</div> */}
                  </div>
                  {/* <div className="widget-content-right header-user-info ms-3">
                    <button
                      type="button"
                      id="Tooltip-1"
                      className="btn-shadow p-1 btn btn-info btn-sm"
                    >
                    <i class="fa-regular fa-calendar"></i>
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {accountModal && (
        <div className="accountOverlay">
          <div
            tabIndex={-1}
            role="menu"
            aria-hidden="false"
            className="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-end show"
            data-popper-placement="bottom-end"
            style={{
              position: "absolute",
              inset: "14px 38px auto auto",
              transform: "translate(0px, 44px)",
            }}
          >
            <ul className="nav flex-row">
              {/* <li className="nav-item">
                <a href="#" className="nav-link">
                  Edit Profile
                </a>
              </li> */}
              {/* <hr style={{ margin: "5px" }} /> */}
              {/* <li className="nav-item-header nav-item">My Account</li> */}

              <li className="nav-item">
                <a className="nav-link">
                  <div
                    className="ms-auto badge bg-warning"
                    onClick={logouthandeler}
                  >
                    Logout
                  </div>
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link">
                  <div
                    class="btn btn-primary"
                    style={{ padding: "0px 10px" }}
                    onClick={() => setModel(true)}
                  >
                    Profile
                  </div>
                </a>
              </li>

                <li className="nav-item">
                <a className="nav-link">
                  <div
                    class="btn btn-primary"
                    style={{ padding: "0px 10px" }}
                    onClick={() => navigate('/change-password')}
                  >
                    Change Password
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}

      {model && (
        <div className="accountOverlay">
          <div
            tabIndex={-1}
            role="menu"
            aria-hidden="false"
            className="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-end show"
            data-popper-placement="bottom-end"
            style={{
              position: "absolute",
              // inset: "14px 38px auto auto",
              inset: "35% 40%",
              // transform: "translate(0px, 44px)",
              height: "30%",
              width: "30%",
            }}
          >
            <button
              // className="close_btn"
              style={{
                marginLeft: "90%",
                backgroundColor: "red",
                border: "none",
              }}
              onClick={() => setModel(false)}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>


            {
                hide ? <div style={{ textAlign:"center" , margin:'12px'}}>
                Name :<input type="text" value={AdminName} onChange={(e)=> setAdminName(e.target.value)}/>
                <button type="button"  style={{     marginLeft: "13px",
    height: "27px",
    padding: "0px 8px"}} onClick={() => profileUpdate()}>Update</button>
                </div> : null
            }
              <div >
                     <h2 style={{ textAlign : "center"}}>
                {name}
              </h2>
              <div style={{ textAlign : "center"}}>
                Email : {email}
              </div>
              <div style={{ textAlign : 'center' , marginTop: "14px"}}>
              <button type="button"  onClick={() =>{ setAdminName(name); setHide(true)}}>Edit</button>
              </div>
              </div>
             

          </div>
        </div>
      )}
    </>
  );
}
