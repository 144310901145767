import React, { useEffect, useState } from "react";
import HomeService from "../../Service/HomeService";
import toast from "react-hot-toast";
import HttpClientXml from "../../Utils/HttpClientXml";
import { useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import SelectableInput from "../../Component/SelectableInput";

const INITIAL = {
  name: "",
  description: "",
  image: "",
  link: "",
  text: [],
};

const AddType = () => {
  const location = useLocation();
  console.log("location", location);
  const [allPhotoCat, setAllPhotoCat] = useState([]);
  const [photoCategoryId, setPhotoCategoryId] = useState("");

  const [typeName, setTypeName] = useState("");
  const [typeData, setTypeData] = useState([]);

  const [theme, setTheme] = useState("");

  const [AllCatData, setAllCatData] = useState([]);

  const [hide, setHide] = useState(true);

  const [collectionCategoryId, setCategoryCollectionId] = useState("");
  const [editEnable, setEditEnable] = useState(false);

  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");

  const [title, setTitle] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [photoGallery, setPhotoGallery] = useState([INITIAL]);

  const [type, setType] = useState("");

  const [themeData, setThemeData] = useState([]);
  const [themeId, setThemeId] = useState("");

  const navigate = useNavigate();

  const [startupdata, setStartupData] = useState([]);

  useEffect(() => {
    fetchAllThematic();
  }, []);

  useEffect(() => {
    fetchAllTheme();
  }, []);

  useEffect(() => {
    FetchAllType();
  }, [theme]);

  const fetchAllTheme = () => {
    // let arr=[];
    HomeService.AllThematicCollection()
      .then((res) => {
        console.log("ResAllTheme", res.data);
        if (res && res?.status) {
          res?.data.map((item) => {
            if (item?.name === "Startups in Nagaland") {
              setTheme(item?._id);
              // arr.push(item)
            }
          });

          // setThemeData(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        //   setLoader(false)
        console.log("err", err);
      });
  };

  const FetchAllType = () => {
    if (theme) {
      HomeService.viewEduStartupType(theme)
        .then((res) => {
          console.log("ResAllDATA", res.data);
          if (res && res?.status) {
            setTypeData(res?.data);
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          //   setLoader(false)
          console.log("err", err);
        });
    }
  };

  const onEdit = (item) => {
    console.log("EDIT", item);
    navigate("/edit-type", { state: item });
  };

  const fetchAllThematic = () => {
    let data = {
      themeType: "startup",
    };

    HomeService.ViewAllThematicType(data)
      .then((res) => {
        if (res && res?.status) {
          // setEducationData(res?.data);

          let arr = res?.data?.map((item, index) => {
            return {
              sl: index + 1,
              themeType: item?.themeType,
              type: item?.type,
              Name: item?.name,
              // Description: item?.description,
              image: (
                <img
                  style={{
                    height: "30%",
                    width: "30%",
                    marginTop: "12px",
                    borderRadius: "9px",
                  }}
                  src={item?.image}
                />
              ),
              action: (
                <div style={{ display: "flex", flexDirection: "coloum" }}>
                  <svg
                    onClick={() => onEdit(item)}
                    style={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>

                  <svg
                    onClick={() => onDelete(item?._id)}
                    style={{
                      color: "red",
                      height: "20px",
                      cursor: "pointer",
                      width: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </div>
              ),
            };
          });

          setStartupData(arr);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HomeService.DeleteThematic(id)
          .then((res) => {
            console.log("RESPONSEDELETE", res);
            if (res && res.status) {
              toast.success(" Deleted successfully");
              fetchAllThematic();
            } else {
              toast.error(res?.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,

      sortable: true,
      width:"80px",
    },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       ThemeType
    //     </div>
    //   ),
    //   selector: (row) => row.themeType,
    // },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Type
        </div>
      ),
      selector: (row) => row.type,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Name
        </div>
      ),
      selector: (row) => row.Name,
    },

    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       Description
    //     </div>
    //   ),
    //   selector: (row) => row.Description,
    // },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Image
        </div>
      ),
      selector: (row) => row.image,
    },
    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
    },
  ];

  const fetchAllPhotography = (id) => {
    HomeService.getAllPhotography(id)
      .then((res) => {
        console.log("responseget ALl PhotoGraphy", res);
        if (res && res?.status) {
          setAllPhotoCat(res?.data);
          // setCategoryPhotography(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        // setLoader(false)
        console.log("err", err);
      });
  };

  const handleCollCatName = (e) => {
    setCategoryCollectionId(e.target.value);

    e.target.value === ""
      ? setCategoryCollectionId("")
      : fetchAllPhotography(e.target.value);
    setHide(false);
  };

  const changeHandler = async (e, i) => {
    if (e.target.name === "image") {
      toast.success("Please upload images with a height of 158 pixels and a width of 235 pixels.")
      let file = e.target.files[0];

      // setPhotoGallery((prev) => {
      //   let update = JSON.parse(JSON.stringify(prev));
      //   update[i].image = URL.createObjectURL(file);
      //   return [...update];
      // });

      let data = new FormData();

      data.append("image", file);
      try {
        let res = await HttpClientXml.fileUplode(
          "upload-Photocategory",
          "POST",
          data
        );
        if (res && res?.status) {
          console.log("Response Startup", res);
          toast.success("Image uploaded successfully");

          setPhotoGallery((prev) => {
            let update = JSON.parse(JSON.stringify(prev));
            update[i].image = res?.doc?.url;
            return [...update];
          });
        } else {
          toast.error("Failed to upload Image");
        }
      } catch (error) {
        toast.error("Failed to upload Image");
      }
      return;
    }

    // if(e.target.name  === "text"){
    //   setPhotoGallery((prev) => {
    //     let update = JSON.parse(JSON.stringify(prev));

    //     console.log("UPDATE", update);
    //     update[i][e.target.name] = e;
    //     return [...update];
    //   })

    //   return;
    // }

    setPhotoGallery((prev) => {
      let update = JSON.parse(JSON.stringify(prev));

      console.log("UPDATE", update);
      update[i][e.target.name] = e.target.value;
      return [...update];
    });
  };

  const handleType = (e) => {
    setTypeName(e.target.value);
  };

  const HandleThematic = () => {
    let data = {
      name: "",
      themeType: "startup",
      type: typeName,
      collection: photoGallery,
    };

    console.log("DataThematic", data);

    if (
      typeName &&
      photoGallery[0].name &&
      photoGallery[0].description &&
      photoGallery[0].image &&
      photoGallery[0].link &&
      photoGallery[0].text.length !== 0
    ) {
      console.log("AA");
      HomeService.AddStartupsType(data)
        .then((res) => {
          console.log("Response Add StartupsType", res);
          if (res && res.status) {
            toast.success("Added Successfully");
            setType("");
            setTitle("");
            setPhotoGallery([INITIAL]);
            let file = document.querySelector("#image");
            file.value = "";
            fetchAllThematic();
            setTypeName("");
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("All fields are required");
    }

    console.log("DataPHOto", data);
  };

  const HandleCrossClick = (i) => {
    // photoGallery[i].image == "";
    // setImage("");
    let file = document.querySelector("#image");
    file.value = "";
  };

  const HandlePhotogallerys = (e) => {
    setPhotoCategoryId(e.target.value);
  };
  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Add Type
            </div>

            {/* 
            <div class="form-group">
              <label for="exampleInputEmail1">Type<span style={{ color:'red'}}>*</span> :</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={type}
                onChange={(e) => setType(e.target.value)}
                placeholder="Enter type..."
              />
            </div> */}

            <label for="exampleInputEmail1">
              Type Name<span style={{ color: "red" }}>*</span> :
            </label>

            <select
              style={{ marginBottom: "21px" }}
              class="form-select"
              aria-label="select category"
              value={typeName}
              onChange={(e) => handleType(e)}
            >
              <option value={""}>Select a type name.......</option>
              {typeData.map((item) => {
                return (
                  <option id={item?._id} value={item?.typeName}>
                    {item?.typeName}
                  </option>
                );
              })}
            </select>

            <div style={{ float: "right", marginRight: "0px" }}>
              <button
                class="btn btn-warning logout-btn"
                onClick={() => {
                  setPhotoGallery((prev) => {
                    let update = JSON.parse(JSON.stringify(prev));
                    update.push({
                      name: name,
                      image: image,
                    });
                    return [...update];
                  });
                }}
              >
                +
              </button>
            </div>

            {photoGallery.map((item, i) => {
              return (
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        marginBottom: "21px",
                        border: "0.01px solid #dee2e6",
                        width: "70%",
                        padding: "13PX",
                      }}
                    >
                      <div class="form-group">
                        <label
                          for="exampleInputEmail1"
                          style={{ marginBottom: "12px", fontSize: "15px" }}
                        >
                          Name<span style={{ color: "red" }}>*</span> :
                        </label>
                        <input
                          type="text"
                          name="name"
                          value={item?.name}
                          onChange={(e) => changeHandler(e, i)}
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter Name"
                        />
                      </div>

                      <div class="form-group">
                        <label
                          for="exampleInputEmail1"
                          style={{ marginBottom: "12px", fontSize: "15px" }}
                        >
                          Description <span style={{ color: "red" }}>*</span> :
                        </label>
                        <input
                          type="text"
                          name="description"
                          value={item?.description}
                          onChange={(e) => changeHandler(e, i)}
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter description"
                        />
                      </div>

                      <div class="form-group">
                        <label
                          for="exampleInputEmail1"
                          style={{ marginBottom: "12px", fontSize: "15px" }}
                        >
                          Link <span style={{ color: "red" }}>*</span> :
                        </label>
                        <input
                          type="text"
                          name="link"
                          value={item?.link}
                          onChange={(e) => changeHandler(e, i)}
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter description"
                        />
                      </div>

                      <div>
                        <SelectableInput
                          title="Search Tag* :"
                          // name="text"
                          value={item?.text}
                          onChange={(e) => {
                            setPhotoGallery((prev) => {
                              let update = JSON.parse(JSON.stringify(prev));
                              update[i].text = e;
                              return update;
                            });
                          }}
                          className="form-group"
                          placeholder="Enter keyword(s) to search"
                        />
                      </div>

                      <div class="mb-3">
                        <label for="formFile" class="form-label">
                          Upload Image<span style={{ color: "red" }}>*</span> :
                        </label>
                        <input
                          id="image"
                          onChange={(e) => changeHandler(e, i)}
                          class="form-control"
                          name="image"
                          type="file"
                          accept="image/*"
                        />

                        {photoGallery[i]?.image !== "" && (
                          <>
                            {/* {photoGallery[i]?.isLoading && (
                              <p style={{ color: "red" }}>
                                Uploading, Please wait ....
                              </p>
                            )} */}
                            <img
                              style={{
                                height: "25%",
                                width: "25%",
                                marginTop: "12px",
                                borderRadius: "12px",
                              }}
                              src={photoGallery[i]?.image}
                            />
                            {/* <button onClick={() => HandleCrossClick(i)} style={{ color: 'red'}} type="button" class="btn-close" aria-label="Close"></button> */}
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      {i == 0 ? null : (
                        <>
                          <button
                            class="btn btn-danger"
                            style={{
                              margin: "104px 88px",
                              padding: "5px , 17px",
                            }}
                            onClick={() => {
                              setPhotoGallery((prv) => {
                                let update = JSON.parse(JSON.stringify(prv));
                                update.splice(i, 1);
                                return update;
                              });
                            }}
                          >
                            -
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </>
              );
            })}

            <button class="btn btn-primary" onClick={HandleThematic}>
              Submit
            </button>

            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              View All List
            </div>

            <DataTable columns={columns} data={startupdata} pagination />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddType;
